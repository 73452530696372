import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {  string, z } from "zod";
import { AiOutlineClose } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingButton from "../../components/loading/LoadingButton";
import { useSelector } from "react-redux";
const schema = z.object({
  name: string().min(3, {
    message: "Name needs to be more 3 characters",
  }),
  phone: string().min(9, {
    message: "A valid Phone number is required",
  }),
  email: string().email( {
    message: "A valid Email is required ",
  }),
  password: string().min(8, {
    message: "Password is too short",
  }),
 
});
const AddUsers = ({setOpen}) => {
    const [input, setInput] = useState(0);
    const [station, setStation] = useState(0)
    const [region, setRegion] = useState(0)
    const [regions, setRegions] = useState([])
    const [roles, setRoles] = useState([]);
    const [stations, setStations] = useState([]);
    const [formInputs, setFormsInput] = useState({})
    const [loading, setLoading] = useState(false);
    const {currentUser} = useSelector((state) => state.user)
    const { register, handleSubmit, formState } = useForm({
      defaultValues: { name: "", email: "", phone: "", password:"" },
      resolver: zodResolver(schema),
    });
    const handleInputFormChange = (e)=>{
      setFormsInput((prev)=>{
        return {...prev, [e.target.name]:e.target.value}
      })
    }
    axios.defaults.headers.common = {
      'Authorization': 'Bearer ' + currentUser.token
  };
    useEffect(() => {

      const fetchRoles = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_APIENDPOINT}users/roles/all`,
            {
                headers: {
                  Authorization: 'Bearer ' + currentUser.token //the token is a variable which holds the token
                }
            },
          );
          
        
          if(currentUser.user.roles[0].id===6){
            setRoles(res.data.data.filter((value)=>{
              return value.id !== 6
            }).filter(object=>{
              return object.id !== 1
            }));
          }else{
            setRoles(res.data.data.filter((value)=>{
              return value.id !== 6
            }).filter(object=>{
              return object.id !== 1
            }).filter(object=>{
              return object.id !== 7
            }));
          }
         
        } catch (error) {
        
          console.log(error);
        }
      };
      const fetchStations = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_APIENDPOINT}operations/stations/${currentUser.user.id}`,
            {
                headers: {
                  Authorization: 'Bearer ' + currentUser.token //the token is a variable which holds the token
                }
            },
            { withCredentials: true }
          );
         
          setStations(res.data);
          console.log(res.data);
         
        } catch (error) {
        
          console.log(error.message);
        }
      };
      const fetchRegions = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_APIENDPOINT}operations/details/${currentUser.user.id}`,
            {
                headers: {
                  Authorization: 'Bearer ' + currentUser.token //the token is a variable which holds the token
                }
            },
            { withCredentials: true }
          );
         
          setRegions(res.data);
          console.log(res.data.regions);
         
        } catch (error) {
        
          console.log(error.message);
        }
      };
      fetchRoles();
      fetchStations();
      fetchRegions();
    }, [currentUser]);
    const { errors } = formState;
    const handleSelectChange = async (e) => {
      setInput(e.target.value);
     
      
    };
    const handleSelectStation = async (e) => {
      setStation(e.target.value);
     
      
    };
    const handleSelectRegion = async (e) => {
      setRegion(e.target.value);
      console.log(input)
     
      
    };
    
    const handleUpdate = async (formValues) => {
      console.log(input)
     
      if (input === 0|| input ==="") {
        toast.warning("Please select Role");
        return;
      }  if (input === "4" ) {
        console.log("clicked")
        if(region === 0|| region ===""|| formInputs.business === null || formInputs.location === null)
        {
          toast.warning("Please fill all fields");
          return;
        }
      }   if (input === "2" || input === "5" || input ==="8"|| input === "9") {
        if(station === 0|| station ===""){
          toast.warning("Please select Station");
          return;
        }
      } if(input === "3" && formInputs.region ===  null){
        toast.warning("Please fill region");
        return;
      } 
      
        //call api
        setLoading(true);
        try {
          axios.defaults.headers.common = {
            'Authorization': 'Bearer ' + currentUser.token
        };
          const bodyReq ={ username: formValues.name, email: formValues.email, phone: formValues.phone, password: formValues.password, id: input};
      
          const res= await axios.post(
            `${process.env.REACT_APP_APIENDPOINT}users/register`,
          
  
            {...bodyReq}
            
          );
          
          if(input === "2" || input === "5" || input ==="8"|| input === "9"){
            const bodyEmp = { userId: res.data.id, stationId: station};
            await axios.post(
              `${process.env.REACT_APP_APIENDPOINT}users/addEmployee`,
              
              {...bodyEmp},
            );
  
          }
          if(input === "3"){
            console.log("started adding region details")
            await axios.post(
              `${process.env.REACT_APP_APIENDPOINT}users/addRegions`,
            
            
              { userId: res.data.id, name: formInputs.region,franchiserId:regions.franchiserId   },
             
            );
          }
          if(input === "4"){
   
            const bodyDe ={ userId: res.data.id, name: formInputs.business,regionId:region, location: formInputs.location };
            await axios.post(
              `${process.env.REACT_APP_APIENDPOINT}users/addDealers`,
             
    
              {...bodyDe},
              
            );
          }
          if(input === "7"){
   
            const bodyDe ={ userId: res.data.id, franchiserId: currentUser.user.id };
            await axios.post(
              `${process.env.REACT_APP_APIENDPOINT}users/addOMCAdmin`,
             
    
              {...bodyDe},
              
            );
          }
          setLoading(false);
          setOpen(false);
        } catch (error) {
          setLoading(false);
          console.log(error.message);
        }
      
    };
  return (
    <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
      <section className="max-w-4xl md:w-1/2 p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Add User
          </h2>
          <AiOutlineClose
            size={30}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>

        <form onSubmit={handleSubmit(handleUpdate)}>
          <div className="grid grid-cols-2 gap-6 mt-4 ">
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="username"
              >
                Name
              </label>
              <input
                type="text"
                placeholder="User Name"
                {...register("name")}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
              <p className="text-red-500 text-sm">
                {errors.name?.message}
              </p>
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                {...register("email")}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
              <p className="text-red-500 text-sm">
                {errors.email?.message}
              </p>
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="phone"
              >
                Phone
              </label>
              <input
                type="number"
                {...register("phone")}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
              <p className="text-red-500 text-sm">
                {errors.phone?.message}
              </p>
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                {...register("password")}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
              <p className="text-red-500 text-sm">
                {errors.password?.message}
              </p>
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="role"
              >
                Role 
              </label>
              <select
                onChange={handleSelectChange}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="">Select Roles</option>
                {roles.map((r) => (
                  <option value={r.id}>{r.roleName}</option>
                ))}
              </select>
            </div>
            <p></p>
            </div>
           {
            input === "2" || input ==="8"|| input === "9"?<div className="grid grid-cols-2 gap-6 mt-4 ">            

            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="role"
              >
                Station
              </label>
              <select
                onChange={handleSelectStation}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="">Select Station</option>
                {stations.map((s) => (
                  <option value={s.stationId}>{s.stationName}</option>
                ))}
              </select>
            </div>
        
        </div>:input === "3"? <div className="grid grid-cols-2 gap-6 mt-4 ">            
          <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="username"
              >
                Name of Region
              </label>
              <input
                type="text"
                onChange={handleInputFormChange}
                name="region"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
              <p className="text-red-500 text-sm">
                {errors.region?.message}
              </p>
            </div>
           
          
          </div>:input === "4"?<div className="grid grid-cols-2 gap-6 mt-4 ">
          <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="business"
              >
                Name of business-(add territory manager's name if coco)
              </label>
              <input
                type="text"
                onChange={handleInputFormChange}
                name="business"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
            
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="role"
              >
                Region
              </label>
              <select
                onChange={handleSelectRegion}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="">Select Region</option>
                {regions.regions.map((r) => (
                  <option value={r.id}>{r.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="location"
              >
                Location
              </label>
              <input
                type="text"
                onChange={handleInputFormChange}
                name="location"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
             
            </div>
          
          </div>:input==="5"?
          <div className="grid grid-cols-2 gap-6 mt-4 ">
         
         <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="role"
              >
                Station
              </label>
              <select
                onChange={handleSelectStation}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="">Select Station</option>
                {stations.map((s) => (
                  <option value={s.id}>{s.name}</option>
                ))}
              </select>
            </div>
        
          
          
          </div>:<div></div>
           }

          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            >
               {loading?<div className="flex gap-4"><LoadingButton/> <p>Adding...</p></div>:"Add"}
            </button>
          </div>
        </form>
      </section>
      <ToastContainer/>
    </div>
  )
}

export default AddUsers