import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import OwnerSideNav from "./components/sidenavs/OwnerSideNav";
import OwnerDashboard from "./pages/owner/OwnerDashboard";
import RegisterClient from "./pages/admin/RegisterClient";
import OwnerAnalytics from "./pages/owner/OwnerAnalytics";
import Performance from "./pages/owner/Performance";
import Logistics from "./pages/owner/Logistics";
import Stations from "./pages/owner/Stations";
import Users from "./pages/owner/Users";
import Site from "./pages/landingpage/Site";
import SiteNav from "./components/nav/SiteNav";
import AccessDenied from "./pages/errors/AccessDenied";
import ForgotPasswordSent from "./pages/auth/ForgotPasswordSent";
import ChangePassword from "./pages/auth/ChangePassword";
import PasswordChanged from "./pages/auth/PasswordChanged";
import ChooseSide from "./pages/auth/ChooseSide";
import AdminSideNav from "./components/sidenavs/AdminSideNav";
import AdminDashboard from "./pages/admin/AdminDashboard";
import CustomerSupportAdmin from "./pages/admin/CustomerSupportAdmin";
import OwnerSupport from "./pages/owner/OwnerSupport";
import Settings from "./pages/owner/Settings";
import Clients from "./pages/admin/Clients";
import AdminSettings from "./pages/admin/AdminSettings";
import PerformaceCenter from "./pages/owner/PerformaceCenter";
import RDashboard from "./pages/regional/RDashboard";
import RegionalSideNav from "./components/sidenavs/RegionalSideNav";
import RUsers from "./pages/regional/RUsers";
import RStations from "./pages/regional/RStations";
import RSettings from "./pages/regional/RSettings";
import OwnerAnalyticsOMC from "./pages/omcadmins/OwnerAnalyticsOMC";
import PerformaceCenterOMC from "./pages/omcadmins/PerformaceCenterOMC";
import LogisticsOMC from "./pages/omcadmins/LogisticsOMC";
import StationsOMC from "./pages/omcadmins/StationsOMC";
import UsersOMC from "./pages/omcadmins/UsersOMC";
import OwnerSupportOMC from "./pages/omcadmins/OwnerSupportOMC";
import PerformanceOMC from "./pages/omcadmins/PerformanceOMC";
import OmcAdminsNav from "./components/sidenavs/OmcAdminsNav";
import OwnerDashboardOMC from "./pages/omcadmins/OwnerDashboardOMC";
import PageNotFound from "./pages/errors/PageNotFound";
import Unsubmitted from "./pages/owner/Unsubmitted";
import UnsubmittedOMC from "./pages/omcadmins/UnsubmittedOMC";
import TC from "./pages/landingpage/TC";
import Privacy from "./pages/landingpage/Privacy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/omc" element={<OwnerSideNav />}>
          <Route index element={<OwnerDashboard />} />
          <Route path="analytics" element={<OwnerAnalytics />} />
          <Route path="performance" element={<PerformaceCenter />} />
          <Route path="logistics" element={<Logistics />} />
          <Route path="stations" element={<Stations />} />
          <Route path="stations/unsubmitted" element={<Unsubmitted />} />
          <Route path="users" element={<Users />} />
          <Route path="support" element={<OwnerSupport />} />
          <Route path="stationDetails" element={<Performance/>}/>
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="/admin" element={<AdminSideNav />}>
          <Route index element={<AdminDashboard />} />
          <Route path="add" element={<RegisterClient />} />
          <Route path="all" element={<Clients />} />
          <Route path="support" element={<CustomerSupportAdmin />} />
          <Route path="settings" element={<AdminSettings />} />
        </Route>
        <Route path="/">
          <Route index element={<Site />} />
          <Route path="terms&conditions" element={<TC />} />
          <Route path="privacy-policy" element={<Privacy />} />
        </Route>
        <Route path="/omcadmins" element={<OmcAdminsNav />}>
        <Route index element={<OwnerDashboardOMC />} />
          <Route path="analytics" element={<OwnerAnalyticsOMC />} />
          <Route path="performance" element={<PerformaceCenterOMC />} />
          <Route path="logistics" element={<LogisticsOMC />} />
          <Route path="stations" element={<StationsOMC />} />
          <Route path="stations/unsubmitted" element={<UnsubmittedOMC />} />
          <Route path="users" element={<UsersOMC />} />
          <Route path="support" element={<OwnerSupportOMC />} />
          <Route path="stationDetails" element={<PerformanceOMC/>}/>
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="/region" element={<RegionalSideNav />}>
          <Route index element={<RDashboard />} />
          <Route path="users" element={<RUsers />} />
          <Route path="stations" element={<RStations />} />
          <Route path="account" element={<RSettings />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="choose" element={<ChooseSide />} />
        <Route path="register" element={<RegisterClient />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password-sent" element={<ForgotPasswordSent />} />
        <Route path="permission-denied" element={< AccessDenied/>} />
        <Route path="*" element={< PageNotFound/>} />
        <Route path="verify/*" element={< ChangePassword/>} />

        <Route path="password-changed" element={< PasswordChanged/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
