/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TablePagination from "../../components/pagination/Pagination";
import LoadingPage from "../../components/loading/LoadingPage";
import { AiOutlineArrowUp } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Bar,Line } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
    LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Monthly Sales  Analytics",
    },
  },
};
export const options3= {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Monthly Shortage  Analytics",
    },
  },
};
const PerformaceCenter = () => {
  const [stations, setStations] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [monthlyShortage,setMonthlyShortage] = useState([])
  
  const [monthYear, setMonthYear] = useState(`${new Date().getMonth()+1}-${new Date().getFullYear()}`);
  const [loading, setLoading] = useState(true);
  const [productType, setProductType] = useState("");
  const [sellings, setSellings] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(7);
  const { currentUser } = useSelector((state) => state.user);
  const [selectedStation, setSelectedStation] = useState({});
  const [stationId, setStationId] = useState();

  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const fetchStations = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/monthly/station/${
          currentUser.user.id
        }/${monthYear.split("-")[0]}/${monthYear.split("-")[1]}`
      );
      setStations(res.data);
      setStationId(res.data[0].stationId)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchSellings =async()=>{
    console.log(selectedStation)
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/monthly/selling/${
          currentUser.user.id
        }/${stationId}`
      );
      setSellings(res.data.filter((value)=>{
        return  value.type ===productType && value.month ===Number(monthYear.split("-")[0])
      }));
      console.log(monthYear.split("-")[0])
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  const fetchMonthly = async () => {
    console.log(stationId)
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/monthly/sales/${currentUser.user.id}/${stationId}`
      );
      console.log(stationId);
      console.log(res.data)
      const res2 = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/monthly/shortage/${currentUser.user.id}/${stationId}`
      );
      
      setMonthlyData(res.data);
      setMonthlyShortage(res2.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(()=>{
      fetchStations()
  },[])
  const modifiedData = stations.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  useEffect(() => {
    fetchMonthly();
    fetchSellings()
  }, [monthYear, productType, stationId]);
  
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentStations = modifiedData.slice(firstPostIndex, lastPostIndex);

  const labels = monthlyData.map((data) => {
    return data.month + " " + data.year;
  });
 
 
 
  var arrayD = [];
  monthlyData.map((e) => {
    e.data.map((d) => {
      arrayD.push(d);
    });
  });
  var arrayS = [];
  monthlyShortage.map((e) => {
    e.data.map((d) => {
      arrayS.push(d);
    });
  });
  const groupedD = arrayD.reduce((group, arr) => {
    const { type } = arr;
    group[type] = group[type] ?? [];
    group[type].push(arr.amount);
    return group;
  }, {});

  const groupedS = arrayS.reduce((group, arr) => {
    const { type } = arr;
    group[type] = group[type] ?? [];
    group[type].push(arr.amount);
    return group;
  }, {});
  let fuels = Object.keys(groupedD);
  let fuelsShortage = Object.keys(groupedS);
  const data3 = {
    labels,
    datasets: fuels.map((f) => {
      return {
        label: f,
        data: groupedD[f],
        backgroundColor:
          f === "Diesel" ? "#00CC00" : f === "Petrol" ? "#324DDD" : "#FFC600",
      };
    }),
  };
  
  const data4 = {
    labels,
    datasets: fuelsShortage.map((f) => {
      return {
        label: f,
        data: groupedS[f],
        backgroundColor:
          f === "Diesel" ? "#00CC00" : f === "Petrol" ? "#324DDD" : "#FFC600",
      };
    }),
  };
  console.log(sellings)
  const sellingData = {
  labels: sellings.map((s)=>{
    return s.day
  }),
  datasets: [
    {
      label: "Sell in",
      data: sellings.map((s)=>{
    return s.received
  }),
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "#5C59E8"
    },
    {
      label: "Sell Out",
      data: sellings.map((s)=>{
    return s.sold
  }),
      fill: false,
      borderColor: "#E46A11"
    }
  ]
};
  return (
    <section className="lg:w-[84%]  w-full lg:ml-48 mx-auto md:px-10 px-3 py-5 ">
      {loading ? (
        <div className="flex flex-col justify-center h-screen items-center">
          <LoadingPage />
          <p>Loading page...</p>
        </div>
      ) : (
        <>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-16">
            <div className="border border-slate-200 border-solid shadow rounded p-4">
              <div className="flex items-center justify-between ">
                <p className="text-sm">Stations</p>
                <BsThreeDotsVertical />
              </div>
              <div className="flex items-center justify-between pt-2">
                <p className="text-4xl">{stations.length}</p>
                <div className="flex items-center rounded-xl text-xs gap-2 bg-slate-200 text-blue-500 px-2">
                  <AiOutlineArrowUp />
                  <p>20%</p>
                </div>
              </div>
            </div>
          </div>
          <section className="mx-auto block w-full mt-8">
            <div className="flex justify-between w-full items-center ">
              <h2 className="font-bold mx-8">Top Selling stations</h2>
             

             <div className="flex gap-2 flex-wrap md:w-1/2 justify-end w-full">
             <select onChange={(e)=>{setSelectedStation(JSON.parse(e.target.value)); setStationId(JSON.parse(e.target.value).stationId);  }} value={selectedStation.stationName}  className="py-2 w-48 text-sm text-white px-3 bg-[#FFC600] rounded">
          <option value="">{selectedStation.stationName}</option>
          {modifiedData.map((station)=>(
            <option value={JSON.stringify(station)}>{station.stationName }</option>
          ))}
        </select>
        <select onChange={(e)=>{setMonthYear(e.target.value)}} className="py-2 w-48 text-sm text-white px-3 bg-[#FFC600] rounded">
          <option value="">Filter by Month</option>
          {monthlyData.map((month)=>(
            <option value={month.monthNumber+"-"+month.year }>{month.month+"-"+month.year }</option>
          ))}
        </select>
             </div>

              
            </div>
            {currentStations.length > 0 ? (
              <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                <thead className="text-white">
                  {currentStations.map((station) => (
                    <tr
                      key={station.id}
                      className="bg-[#1C144A] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2  sm:mb-0"
                    >
                      <th scope="col" className="p-3 text-left">
                        Station Name
                      </th>
                      <th scope="col" className="p-3 text-left">
                        Location
                      </th>
                      <th scope="col" className="p-3 text-left">
                        Region{" "}
                      </th>
                      <th scope="col" className="p-3 text-left">
                        Territory Manager
                      </th>

                      <th scope="col" className="p-3 text-left">
                        Gross sales
                      </th>
                      <th scope="col" className="p-3 text-left">
                        Target
                      </th>
                      <th scope="col" className="p-3 text-left">
                        Rating
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody className="flex-1 sm:flex-none">
                  {currentStations.map((station) => (
                    <tr
                      className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                      key={station.stationId}
                    >
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {station.stationName}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {station.location}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {station.region}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {station.territoryManager}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {station.grossSales}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {station.target}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {station.target > station.grossSales ? (
                          <p className="text-red-500">Below Target</p>
                        ) : (
                          <p className="text-green-500">Above Target</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
                <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                  {LoadingPage ? (
                    <div className="block w-48 mx-auto">
                      <LoadingPage /> <p>Loading Page...</p>
                    </div>
                  ) : (
                    <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                      <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </div>
                      <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                        No Data Found
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            )}
            <TablePagination
              setPostsPerPage={setPostsPerPage}
              totalPosts={modifiedData.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
            <Bar options={options2} data={data3} />
            <Bar options={options3} data={data4} />
            <p className="text-xl text-center font-bold">Sell in Vs Sell Out</p>
            <select value={productType} onChange={(e)=>{setProductType(e.target.value)}} className="py-2 w-48 text-sm text-white px-3 bg-[#FFC600] rounded">
              <option value="">Select fuel type</option>
              {fuelsShortage.map((f)=>(
                <option value={f}>{f}</option>
              ))}
            </select>
             {productType !==""&&<Line data={sellingData} />}
          </section>
        </>
      )}
    </section>
  );
};

export default PerformaceCenter;
