import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";
import logo from "../../assets/logo_black.png";
import loginImage from "../../assets/login_image.png"
import LoadingButton from "../../components/loading/LoadingButton";
const schema = z.object({
  username: string().email( {
    message: "A valid Email is required ",
  }),
  password: string().min(4,{
    message: "Password is required with 6 or more characters",
  }),
  
});
const Login = () => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { username: "", password: ""},
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingLogin, setLoadingLogin] = useState(false) 
  const { errors } = formState;

  const handleLogin = async (formValues) => {
    delete axios.defaults.headers.common["Authorization"];
    dispatch(loginStart())
    setLoadingLogin(true)
    //call api
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APIENDPOINT}users/auth/login`,
        
        { ...formValues }
      );
      setLoadingLogin(false)
      dispatch(loginSuccess(res.data))
      navigate("/choose")
        
    } catch (error) {
      setLoadingLogin(false)
        dispatch(loginFailure())
        console.log(error)
      toast.error("Credentials are invalid");
    }
  };
  return (
    <section>
      <div className="flex items-center w-full">
        <div className="flex flex-col h-screen md:w-1/2 w-full justify-center items-center">
          <img src={logo} alt="" className="h-8" />
          <p className="py-3">Sign in</p>
          <p className="py-3">Sign in to stay connected</p>

          <form onSubmit={handleSubmit(handleLogin)} className="w-3/4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm text-gray-500 "
              >
                Email
              </label>

              <input
              {...register("username")}
                type="email"
                className="block  mt-2 w-full placeholder-gray-400/70  rounded-lg border border-gray-400 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40    "
              />
              <p className="text-red-500 text-sm">{errors.username?.message}</p>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm text-gray-700"
              >
                Password
              </label>

              <input
              {...register("password")}
                type="password"
                className="block  mt-2 w-full placeholder-gray-400/70  rounded-lg border border-gray-400 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40   "
              />
              <p className="text-red-500 text-sm">{errors.password?.message}</p>
            </div>

            <div className="flex items-center justify-between my-6">
                <div className="flex items-center gap-2">
                    <input type="checkbox" />
                    <label htmlFor="rememberMe">Remember me</label>
                </div>

                <p><Link to="/forgot-password" className="text-orange-500 hover:text-orange-400">Forgot Password</Link></p>
            </div>

            <button type="submit" className="px-6 block mx-auto my-4 py-2 bg-blue-700 hover:bg-blue-500 text-white rounded"> {loadingLogin?<div className="flex gap-4"><LoadingButton/> <p>Please wait...</p></div>:"Login"}</button>
          </form>
        </div>
        <div className="md:w-1/2 hidden md:block">
            <img src={loginImage} className="h-screen w-full object-cover" alt="" />
        </div>
      </div>
      <ToastContainer/>
    </section>
  );
};

export default Login;
