import React,{useState} from 'react'
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/logo_black.png";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setEmail } from '../../redux/emailSlice';
import LoadingButton from '../../components/loading/LoadingButton';
const schema = z.object({
  email: string().email( {
    message: "A valid Email is required ",
  }),

  
});
const ForgotPassword = () => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { email:""},
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;
  const sendEmail = async (formValues) => {
    try {
      const res = await axios.post(
        `users/auth/forgotpassword`,
        { ...formValues },
        { withCredentials: true }
      );
      console.log(res.data)
      setLoading(false)
    
        dispatch(setEmail(formValues.email))
        navigate("/forgot-password-sent")
    } catch (error) {
      setLoading(false)
        console.log(error)
      toast.error("Email not found");
    }
  }
  return (
    <section>
      <img src={logo} className='h-12 ml-8 mt-4' alt="" />
      <div className='flex flex-col justify-center h-[80vh] items-center'>
        
        <form onSubmit={handleSubmit(sendEmail)} className='md:w-5/12 w-full p-6'>
        <h2 className='text-4xl font-extrabold'>Forgot Password</h2>
        <p className='mt-4'>Enter the email you used to create your account so we can send you instructions on how to reset your password.</p>

        <div className='mt-8'>
              <label
                htmlFor="email"
                className="block text-sm text-gray-500 "
              >
                Email
              </label>

              <input
              {...register("email")}
                type="email"
                className="block  mt-2 w-full placeholder-gray-400/70  rounded-lg border border-gray-400 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40    "
              />
              <p className="text-red-500 text-sm">{errors.email?.message}</p>
            </div>

            <button type='submit' className='bg-[#312ECB] w-full rounded mt-8 px-3 py-2 text-white hover:bg-blue-500'>{loading?<div className="flex gap-4"><LoadingButton/> <p>Please wait...</p></div>:"Send"}</button>


            <Link to="/login" className='block text-center hover:bg-[#312ECB] w-full rounded text-black mt-8 px-3 py-2 hover:text-white border-2 border-solid border-black '>Back to Login</Link>
        </form>
      </div>
      <ToastContainer/>
    </section>
  )
}

export default ForgotPassword