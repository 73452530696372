import React, { useEffect, useState } from 'react'
import TablePagination from '../../components/pagination/Pagination';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LoadingPage from '../../components/loading/LoadingPage';
import Reply from '../../components/modals/Reply';
import { useSelector } from 'react-redux';

const CustomerSupportAdmin = () => {
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [openReply, setOpenReply] = useState();
  const [record, setRecord] = useState({});

  let [filteredData] = useState();
  const {currentUser} = useSelector((state) => state.user)
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const fetchMessages = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIENDPOINT}operations/support/messages`);
      setMessages(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMessages();
  }, []);
  const modifiedData = messages.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentMessages = modifiedData.slice(firstPostIndex, lastPostIndex);
  const handleSearch = (e) => {
    let searchValue = e.target.value;
    if (e.target.value === "") {
      fetchMessages();
    } else {
      filteredData = modifiedData.filter((value) => {
        return value.userEmail.toLowerCase().includes(searchValue.toLowerCase());
      });
      setMessages(filteredData);
      setCurrentPage(1);
    }
  };
  const handleReply = async(record)=>{
    setRecord(record);
    setOpenReply(true);
  }
  const handleMarkAsRead =async (record)=>{
    try {
      await axios.get(`${process.env.REACT_APP_APIENDPOINT}operations/support/mark/${record.id}`);
      fetchMessages();

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <section className="lg:w-[85%]  w-full lg:ml-48 mx-auto px-10 py-3">
      <div className="flex justify-between md:flex-row flex-col  md:mt-16 mt-10 flex-wrap gap-2 items-center">
        <div className="relative flex items-center mt-4 md:mt-0">
          <span className="absolute">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 mx-3 text-gray-400 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </span>

          <input
            type="text"
            onChange={handleSearch}
            placeholder="Search by Email..."
            className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 focus:border-blue-400  focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
          />
        </div>
        
      </div>
     {loading?<div className="flex flex-col justify-center items-center h-[80vh]"><LoadingPage/> <p>Loading messages... </p></div>: <div>
      {currentMessages.length > 0 ? (
        <table class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
          <thead class="text-white">
            {currentMessages.map((product) => (
              <tr
                key={product._id}
                class="bg-gray-800 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
              >
                <th class="p-3 text-left">Email</th>
                <th class="p-3 text-left">Message</th>
                <th class="p-3 text-left">Reply</th>
                <th class="p-3 text-left">Actions</th>
              
               
               
              </tr>
            ))}
          </thead>
          <tbody class="flex-1 sm:flex-none">
            {currentMessages.map((message) => (
              <tr
                key={message.id}
                class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
              >
                <td class="border-grey-light border hover:bg-gray-100 p-3">
                  {message.userEmail}
                </td>
                <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                  {message.message}
                </td>
                <td class="border-grey-light border hover:bg-gray-100 p-3">
                  {message.isReplied === 1? `${message.reply}`:"Message not replied"}
                </td>
                <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
                  {message.isReplied === 0 && <><Link onClick={()=>{handleReply(message)}}>Reply</Link> | <Link onClick={()=>{handleMarkAsRead(message)}}>Mark as Read</Link></>}
                </td>
               
               
                
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mx-auto text-md text-center mt-8">No messages found</div>
      )}
      </div>}

<TablePagination setPostsPerPage={setPostsPerPage}  totalPosts={modifiedData.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
{openReply && <Reply record={record} setOpenReply={setOpenReply} />}

    </section>
  )
}

export default CustomerSupportAdmin