import React from 'react'
import logo from "../../assets/logo_black.png";
import { Link } from 'react-router-dom';
const ChangePassword = () => {
  return (
    <section>
      <img src={logo} className='h-12 ml-8 mt-4' alt="" />
      <div className='flex flex-col justify-center h-[80vh] items-center'>
        
        <form action="" className='md:w-5/12 w-full p-6'>
        <h2 className='text-4xl font-extrabold'>Reset Password</h2>
        <p className='mt-4'>Choose a new password for your account</p>

        <div className='mt-8'>
             

              <input
              // {...register("username")}
                type="password"
                placeholder='Your new Password'
                className="block  mt-2 w-full placeholder-gray-400/70  rounded-lg border border-gray-400 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40    "
              />
              {/* <p className="text-red-500 text-sm">{errors.username?.message}</p> */}
            </div>
            <div >
            

              <input
              // {...register("username")}
              placeholder='Confirm your new password'
                type="password"
                className="block mt-2  w-full placeholder-gray-400/70  rounded-lg border border-gray-400 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40    "
              />
              {/* <p className="text-red-500 text-sm">{errors.username?.message}</p> */}
            </div>

            <button className='bg-[#312ECB] w-full rounded mt-8 px-3 py-2 text-white hover:bg-blue-500'>Reset Password</button>


            <Link to="/login" className='block text-center hover:bg-[#312ECB] w-full rounded text-black mt-8 px-3 py-2 hover:text-white border-2 border-solid border-black '>Back to Login</Link>
        </form>
      </div>
    </section>
  )
}

export default ChangePassword