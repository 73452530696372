import axios from 'axios';
import React,{useEffect, useState} from 'react'
import LoadingPage from '../../components/loading/LoadingPage';
import { useSelector } from 'react-redux';

const AdminDashboard = () => {
  const [analytics, setAnalytics] = useState([]);
  const [loading, setLoading] = useState(false);
  const {currentUser} = useSelector((state) => state.user)
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  useEffect(()=>{
    const fetchAnalytics = async()=>{
      setLoading(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_APIENDPOINT}operations/stats`,);
        setAnalytics(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchAnalytics();
  },[])
  return (
    <section className="lg:w-[85%]  w-full lg:ml-48 mx-auto px-10 py-3">
        {loading?<div className="flex flex-col justify-center h-screen items-center"><LoadingPage/><p>Loading stats...</p></div>:<div className='grid md:grid-cols-3 gap-4 grid-cols-1 sm:grid-cols-2 mt-12'>
          <div className='rounded border-slate-200 border border-solid p-4 shadow-md bg-green-100'>
            <div className='flex flex-col justify-center items-center p-8 font-bold text-3xl'>
              {analytics.totalUsers}
            </div>
            <div className='flex justify-end'><p>Total Users</p></div>
          </div>

          <div className='rounded border-slate-200 border border-solid p-4 shadow-md bg-green-100'>
            <div className='flex flex-col justify-center items-center p-8 font-bold text-3xl'>
              {analytics.disabledUsers}
            </div>
            <div className='flex justify-end'><p>Unactive Users</p></div>
          </div>
          <div className='rounded border-slate-200 border border-solid p-4 shadow-md bg-green-100'>
            <div className='flex flex-col justify-center items-center p-8 font-bold text-3xl'>
              {analytics.totalClients}
            </div>
            <div className='flex justify-end'><p>Clients</p></div>
          </div>





          <div className='rounded border-slate-200 border border-solid p-4 shadow-md bg-green-100'>
            <div className='flex flex-col justify-center items-center p-8 font-bold text-3xl'>
              {analytics.totalMessages}
            </div>
            <div className='flex justify-end'><p>Total Feedback Messages</p></div>
          </div>

          <div className='rounded border-slate-200 border border-solid p-4 shadow-md bg-green-100'>
            <div className='flex flex-col justify-center items-center p-8 font-bold text-3xl'>
              {analytics.unRepliedMessages}
            </div>
            <div className='flex justify-end'><p>UnReplied Messages</p></div>
          </div>
          <div className='rounded border-slate-200 border border-solid p-4 shadow-md bg-green-100'>
            <div className='flex flex-col justify-center items-center p-8 font-bold text-3xl'>
              {analytics.newMessages}
            </div>
            <div className='flex justify-end'><p>New Messages</p></div>
          </div>
        </div>}
    </section>
  )
}

export default AdminDashboard