import React from "react";
import mockup1 from "../../assets/mockup.png";
import { Link } from "react-router-dom";
import appstore from "../../assets/appstore.png";
import playstore from "../../assets/playstore.png";
import appgallery from "../../assets/appgallery.png";
import { Link as HashLink,} from "react-scroll";
const HomePage = () => {
  
  return (
    <div className="bg-[#1C144A]">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full  lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6  font-sans text-center text-3xl font-bold leading-none tracking-tight text-white sm:text-[3.5rem] md:mx-auto">
              <span className="inline-block text-center">All in One </span>
              <span className="inline-block text-center">Retail Perfomance Tool</span>
            </h2>
            <p className="text-base text-indigo-100 md:text-lg text-center">
              Digitalize Your Fuel Retail Operations with Advanced Analytics and
              Seamless Management Tools All in one place .
            </p>
          </div>
          <div>
            <HashLink
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={50}
              duration={2000}
              className="block w-48 cursor-pointer mx-auto text-center  items-center rounded-3xl justify-center py-3 px-6 font-medium tracking-wide text-white transition duration-200  shadow-md bg-[#00CC00] hover:bg-green-400 focus:shadow-outline focus:outline-none"
              
            >
              Book a demo
            </HashLink>
          </div>
        </div>
      </div>

      <img src={mockup1} className="mt-10" alt="" />
      <div className="flex mt-6 justify-center gap-4 flex-wrap">
        <Link>
          <img className="h-10" src={appstore} alt="" />
        </Link>
        <Link>
          <img className="h-10" src={playstore} alt="" />
        </Link>
          <img className="h-12" src={appgallery} alt="" />
        
      </div>
    </div>
    </div>
  );
};

export default HomePage;
