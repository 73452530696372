import React from 'react'
import {BsCheckCircle} from "react-icons/bs"
import { Link } from 'react-router-dom'
const PasswordChanged = () => {
  return (
    <div className='flex flex-col justify-center h-screen items-center'>
      <div className='md:w-5/12 w-full flex flex-col items-center justify-center'>
      <BsCheckCircle size={60} className='text-green-600'/>
        <p className='mt-4'>Password reset successfully</p>
        <Link to="/login" className='bg-[#312ECB] block text-center w-full rounded mt-8 px-3 py-2 text-white hover:bg-blue-500'>Login</Link>
      </div>
    </div>
  )
}

export default PasswordChanged