import React from 'react'
import FeatureCard from '../../components/cards/FeatureCard'
import feature1 from "../../assets/feature1.png";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import feature5 from "../../assets/feature5.png";
import feature6 from "../../assets/feature6.png";

const Features = () => {
  return (
    <section className='bg-white py-10 px-10' id="features">
        <h3 className='text-blue-500'>Features</h3>
        <div className='grid md:grid-cols-3 gap-4'>
            <h2 className='text-4xl w-7/12 leading-[2.8rem] font-semibold'>Easy, Simple, Funtional</h2>
            <p className='leading-7 text-[#596780]'>Our platform  Integrates Functional areas of  Retail  Fuel Operations. These are some of the reasons why you should use our platform in managing  your Operations.</p>
        </div>
        <div className='grid md:grid-cols-3 grid-cols-1'>
          <FeatureCard img={feature1} title="Real Time Performance  Reporting" content="Get 100% visibility and control over company Performance  in real time. No more chasing the team or dealers  for  (Daily /monthly) Performance reports."/>
          <FeatureCard img={feature2} title="Sales Targets  and Monitoring" content="Stay Up to date  With Sales Targets that  you have set for each Station and Monitor them with ease ."/>
          <FeatureCard img={feature3} title="Online  Receiving" content="kkk"/>
          <FeatureCard img={feature4} title="Online Odering" content="Get 100% visibility and control over company Performance in realtime. No more chasing the team or dealers for (Daily/Month) Performance reports"/>
          <FeatureCard img={feature5} title="Dealers & Station Management" content="Stay Up to date  With Sales Targets that  you have set for each Station and Monitor them with ease ."/>
          <FeatureCard img={feature6} title="Employee Management  " content="Easily handle employee records, schedules, and communications, enabling your team to focus on what matters most - driving your fuel business forward."/>
        </div>
    </section>
  )
}

export default Features