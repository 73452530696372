import React from 'react'
import phone from "../../assets/app.png"
import stepper from "../../assets/Stepper.png"
const HowItWorks = () => {
  return (
    <section className='flex bg-white flex-wrap justify-center w-full md:p-8 gap-10 p-2' id="howitworks">
        <div className='md:w-1/2 w-full'>
        <img src={phone} alt="" className='w-5/12 mx-auto' />
        </div>
        <div className='flex flex-col md:items-start items-center justify-center md:w-5/12 w-full'>
            <h2 className='text-[#7C5CFC]'>HOW IT WORKS</h2>
            <h3 className='md:text-5xl text-3xl text-center md:text-left font-bold my-3'>Few Easy Steps and Done</h3>
            <p className='text-[#90A3BF]'>In just few easy step, you are all set to manage your Stations and Sites</p>
            <img src={stepper} className='md:w-1/2 w-full mt-4' alt="" />
        </div>
    </section>
  )
}

export default HowItWorks