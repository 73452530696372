import React from 'react'
import woman  from "../../assets/woman.png"
import ReasonCard from '../../components/cards/ReasonCard'
import benefit1 from "../../assets/benefit1.png";
import benefit2 from "../../assets/benefit2.png";
import benefit3 from "../../assets/benefit3.png";
const MoreInfo = () => {
  return (
    <section className='bg-white py-10 px-10 flex flex-col justify-center items-center' id="whyus">
        <h2 className='font-bold md:text-4xl text-2xl text-center mt-3'>  Your Digital Patner Of Choice </h2>
        <div className='rounded-xl bg-[#42B56C] w-1/4 h-2'></div>

        <div className='flex flex-wrap gap-4 md:flex-row flex-col mt-10 md:px-6 justify-center md:justify-between items-center md:items-start'>
            <img src={woman} alt="" className='md:w-5/12 w-full' />
            <div className='md:w-5/12 w-full'>
                <ReasonCard img={benefit1} title="Dedicated 24/7 Support" content="We prioritize our customers' success. Our team of experts are available round-the-clock to address your queries, provide technical assistance, and ensure a seamless experience with our platform."/>
                <ReasonCard img={benefit2} title="Zero Setup Cost" content="With Fuelytics, you can hit the ground running without any upfront costs. Say goodbye to initial investments, as we eliminate setup expenses, making it easy for you to adopt our platform."/>
                <ReasonCard img={benefit3} title="Comprehensive Free Training" content="We believe in empowering our users hence providing free, training to help your team leverage the platform's full potential, ensuring a smooth onboarding experience for everyone.."/>
                
            </div>
        </div>
    </section>
  )
}

export default MoreInfo