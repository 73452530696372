import React, { useEffect, useState } from "react";
import welcome from "../../assets/welcome.png";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import axios from "axios";
import LoadingPage from "../../components/loading/LoadingPage";
import moment from "moment";
import TablePagination from "../../components/pagination/Pagination";
import { Link } from "react-router-dom";
const LogisticsOMC = () => {
  const [ullage, setUllage] = useState(true);
  const { currentUser } = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState(new Date());
  const [deliveries, setDeliveries] = useState([]);
  const [ullageData, setUllageData] = useState([]);
  const [submission, setSubmission] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(7);
  //const [record, setRecord] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  let [filteredData] = useState();
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const fetchDeliveries = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/deliveries/${currentUser.franchiser}/${moment(startDate).format('YYYY-MM-DD').toString()}`
      );
      setDeliveries(res.data);
      setLoadingPage(false);
      console.log(res.data);
    } catch (error) {
      setLoadingPage(false);
      console.log(error);
    }
  };
  const fetchUllage = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/ullage/${currentUser.franchiser}/${moment(startDate).format('YYYY-MM-DD').toString()}`
      );
      setUllageData(res.data);
      setLoadingPage(false);
      console.log(res.data);
    } catch (error) {
      setLoadingPage(false);
      console.log(error);
    }
  };
  const fetchSubmissions = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/daily/submission/${currentUser.franchiser}/${moment(startDate).format('YYYY-MM-DD').toString()}`
      );
      setSubmission(res.data);

      setLoadingPage(false);
      console.log(res.data);
    } catch (error) {
      setLoadingPage(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSubmissions();
    if (ullage) {
      fetchUllage();
    } else {
      fetchDeliveries();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ullage, startDate]);
  const modifiedDataD = deliveries.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const modifiedDataU = ullageData.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentDeliveries = modifiedDataD.slice(firstPostIndex, lastPostIndex);
  const currentUllage = modifiedDataU.slice(firstPostIndex, lastPostIndex);
  const handleSearchD = (e) => {
    let searchValue = e.target.value;
    if (e.target.value === "") {
      fetchDeliveries();
    } else {
      filteredData = modifiedDataD.filter((value) => {
        return value.stationName.toLowerCase().includes(searchValue.toLowerCase());
      });
      setDeliveries(filteredData);
    }
  };
  const handleSearchU = (e) => {
    let searchValue = e.target.value;
    console.log(searchValue);
    if (e.target.value === "") {
      fetchUllage();
    } else {
      console.log(modifiedDataU)
      filteredData = modifiedDataU.filter((value) => {
        return value.stationName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      
      setUllageData(filteredData);
    }
  };

  var today = new Date();
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <section className="lg:w-[84%]  w-full lg:ml-48 mx-auto md:px-10 px-2 py-5">
      <div className="flex justify-between mt-16">
        <div className="flex flex-col">
          <p className="flex gap-2 items-center md:text-md text-sm">
            <span className="text-slate-400">Welcome</span>{" "}
            <img src={welcome} className="h-5" alt="" />
          </p>
          <p className="text-blue-600 md:text-md text-sm">{currentUser.user.username}</p>
        </div>
        <div className="flex flex-col items-end md:text-md text-sm">
          <p className="">{weekday[today.getDay()]}</p>
          <p className="">
            {today.getDate()} {month[today.getMonth()].substring(0,3)} {today.getFullYear()}
          </p>
        </div>
      </div>
      <Link to="/omcadmin/stations/unsubmitted"className="p-4 rounded-xl shadow-2xl w-full md:w-1/4 mx-auto">
                  <p className="font-bold text-xl py-4">Submissions</p>
                  <div className="flex items-center gap-4">
                    <p className="text-[#2FD72F] text-2xl">
                      {(Number(submission.split("/")[0]) /
                        Number(submission.split("/")[1])) *
                        100}{" "}
                      %
                    </p>
                    <p>{submission}</p>
                  </div>
                  <p className="text-slate-500 py-2 text-xs">
                    Of Sites have Submitted Readings{" "}
                  </p>
                </Link>
      <div className="flex overflow-x-auto mt-2 overflow-y-hidden border-b border-gray-200 whitespace-nowrap dark:border-gray-700">
        <button
          onClick={() => {
            setUllage(true);
            setDeliveries([]);
          }}
          className={
            ullage
              ? "inline-flex items-center h-10 px-4 -mb-px text-sm text-center text-blue-600 bg-transparent border-b-2 border-blue-500 sm:text-base dark:border-blue-400 dark:text-blue-300 whitespace-nowrap focus:outline-none"
              : "inline-flex items-center h-10 px-4 -mb-px text-sm text-center text-gray-700 bg-transparent border-b-2 border-transparent sm:text-base dark:text-white whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400"
          }
        >
          Ullage
        </button>

        <button
          onClick={() => {
            setUllage(false);
            setUllageData([]);
          }}
          className={
            ullage
              ? "inline-flex items-center h-10 px-4 -mb-px text-sm text-center text-gray-700 bg-transparent border-b-2 border-transparent sm:text-base dark:text-white whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400"
              : "inline-flex items-center h-10 px-4 -mb-px text-sm text-center text-blue-600 bg-transparent border-b-2 border-blue-500 sm:text-base dark:border-blue-400 dark:text-blue-300 whitespace-nowrap focus:outline-none"
          }
        >
          Deliveries
        </button>
      </div>

      {ullage ? (
        <div>
          <div className="flex justify-between items-center">
            <div className="mx-auto w-1/2 relative">
              <span className="absolute left-0 top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>

              <input
                type="text"
                onChange={handleSearchU}
                placeholder="Search..."
                className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            <DatePicker className="px-2 py-3 border rounded-lg border-slate-300 border-solid" maxDate={new Date()} selected={startDate} onChange={(date) => {setStartDate(date); }} />
          </div>
          <section className="mx-auto">
            {currentUllage.length > 0 ? (
               <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
               <thead className="text-white">
                  {currentUllage.map((ullage)=>(
                    <tr key={ullage.tankName} className="bg-[#1C144A] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Station
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Tank Name
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Capacity
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Opening stock
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Ullage
                    </th>
                  </tr>
                  ))}
                </thead>
                <tbody className="flex-1 sm:flex-none">
                  {currentUllage.map((ullage) => (
                    <tr key={ullage.tankName} className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                      {moment(startDate).format('YYYY-MM-DD').toString()}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {ullage.stationName}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {ullage.tankName}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {ullage.product}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {ullage.capacity}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {ullage.stock}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {ullage.ullage}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
                <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                  {loadingPage ? (
                    <div className="block w-48 mx-auto">
                      <LoadingPage /> <p>Loading Ullage...</p>
                    </div>
                  ) : (
                    <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                      <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </div>
                      <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                        No Data Found
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            )}
            <TablePagination
              setPostsPerPage={setPostsPerPage}
              totalPosts={modifiedDataU.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </section>
        </div>
      ) : (
        <div>
          
          <div className="flex justify-between items-center mt-4">
            <div className="mx-auto w-1/2 relative">
              <span className="absolute left-0 top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>

              <input
                type="text"
                onChange={handleSearchD}
                placeholder="Search"
                className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            <DatePicker className="px-2 py-3 border rounded-lg border-slate-300 border-solid" maxDate={new Date()}  selected={startDate} onChange={(date) => {setStartDate(date); }} />
          </div>
          <section className="mx-auto">
            {currentDeliveries.length > 0 ? (
              <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
              <thead className="text-white">
                  {currentDeliveries.map((deliveries)=>(
                    <tr className="bg-[#1C144A] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
                    >
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Station
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Received
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Expected
                    </th>
                    <th
                      scope="col"
                      className="p-3 text-left"
                    >
                      Variance
                    </th>
                  </tr>
                  ))}
                </thead>
                <tbody className="flex-1 sm:flex-none">
                  {currentDeliveries.map((deliveries) => (
                    <tr key={deliveries.products.id} className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                      {moment(startDate).format('YYYY-MM-DD').toString()}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {deliveries.stationName}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {deliveries.products.fuel.name}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {deliveries.products.amount}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {deliveries.products.expected}
                      </td>
                      <td className="border-grey-light border hover:bg-gray-100 p-3">
                        {Number(deliveries.products.expected) -
                          Number(deliveries.products.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
                <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                  {loadingPage ? (
                    <div className="block w-48 mx-auto">
                      <LoadingPage /> <p>Loading Deliveries...</p>
                    </div>
                  ) : (
                    <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                      <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </div>
                      <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                        No Data Found
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            )}
            <TablePagination
              setPostsPerPage={setPostsPerPage}
              totalPosts={modifiedDataD.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </section>
        </div>
      )}
    </section>
  );
};

export default LogisticsOMC;
