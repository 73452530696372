import React,{useState} from 'react'
import logo from "../../assets/logo_black.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import LoadingButton from '../../components/loading/LoadingButton';
const ForgotPasswordSent = () => {
    const {email}  = useSelector((state)=>state.email)
    const [loading, setLoading] = useState(false) 
    const resendEmail =async ()=>{
        try{
            await axios.post(
                `users/auth/forgotpassword`,
                {email:email },
                { withCredentials: true }
              );
              setLoading(false)
            }
                catch (error) {
                    setLoading(false)
                      console.log(error)
                    toast.error("Email not found");
                  }
        
    }
  return (
    <section>
      <img src={logo} className='h-12 ml-8 mt-4' alt="" />
      <div className='flex flex-col justify-center h-[80vh] items-center'>
        <div className='md:w-5/12 w-full p-6'>
        <h2 className='text-4xl font-extrabold'>Check Your Email</h2>
        <p className='mt-4'>We have sent an email with password reset information to {email}.</p>

        <p className='mt-10'>Didn’t receive the email?  click the button below to resend it</p>

            <button onClick={resendEmail} className='bg-[#312ECB] w-full rounded mt-8 px-3 py-2 text-white hover:bg-blue-500'>{loading?<div className="flex gap-4"><LoadingButton/> <p>Please wait...</p></div>:"Resend Email"}</button>


            <Link to="/login" className='block text-center hover:bg-[#312ECB] w-full rounded text-black mt-8 px-3 py-2 hover:text-white border-2 border-solid border-black '>Back to Login</Link>
      
        </div>
      </div>
      <ToastContainer/>
    </section>
  )
}

export default ForgotPasswordSent