import React from 'react'

const ReasonCard = ({img, title, content}) => {
  return (
    <div className='flex mt-4 flex-col items-center md:items-start justify-center md:flex-row gap-4'>
        <img className="w-12" src={img} alt=''/>
        <div className='text-center'>
            <h3 className='text-2xl font-bold'>{title}</h3>
            <p className='text-[#596780]'>{content}</p>
        </div>
    </div>
  )
}

export default ReasonCard