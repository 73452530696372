import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  email: null,
  
}
export const emailSlice   = createSlice({
    name: 'email',
    initialState,
    reducers: {
      setEmail:(state, actions)=>{
        state.email=actions.payload;
      },
     
      
    },
  })

  export const {setEmail} = emailSlice.actions;
  export default emailSlice.reducer;