/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart, ArcElement,  
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend, } from "chart.js";

import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useSelector } from "react-redux";
import LoadingPage from "../../components/loading/LoadingPage";
import TablePagination from "../../components/pagination/Pagination";
Chart.register(ArcElement,  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend);


export const options = {
  
  responsive: true,
  plugins: {
    legend: {
      position: 'right' ,
      labels: {
        color: "#fff"
      },
      
    },
    title: {
      display: true,
      text: 'Sales by Product',
      color: "#fff"
    },
    maintainAspectRatio: false ,
    aspectRatio: 1
  },
};
export const options2 = {
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' ,
    },
    title: {
      display: true,
      text: 'Monthly Sales Analytics',
    },
  },
};

const OwnerAnalytics = () => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [modifiedSales, setModifiedSales]=useState([]);
  const [stations, setStations] = useState([]);
  const [monthYear, setMonthYear] = useState(`${new Date().getMonth()+1}-${new Date().getFullYear()}`);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(7);
  const { currentUser } = useSelector((state) => state.user);
  const [monthlyAnalytics, setMonthlyAnalytics] = useState([])
  const[loading, setLoading] =useState(true);
  //let [filteredData] = useState();
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const fetchStations = async()=>{
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIENDPOINT}operations/monthly/station/${currentUser.user.id}/${monthYear.split("-")[0]}/${monthYear.split("-")[1]}`);
      setStations(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }
  useEffect(()=>{
    const fetchMonthly = async()=>{
      setLoading(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_APIENDPOINT}operations/monthly/sales/${currentUser.user.id}`);
        setMonthlyData(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    const fetchMothlyAnalytics = async()=>{
      setLoading(true);
     try {
      const res = await axios.get(`${process.env.REACT_APP_APIENDPOINT}operations/monthly/analytics/${currentUser.user.id}/${monthYear.split("-")[0]}/${monthYear.split("-")[1]}`);
      setMonthlyAnalytics(res.data);
      
      
      
      setModifiedSales(
        res.data.fuelAmountsResponses.map(({ ...item }) => ({
          ...item,
          color:
            item.type === "Diesel"
              ? "#00CC00"
              : item.type === "Petrol"
              ? "#0F60FF"
              : "#E5E600",
        }))
      );
      setLoading(false);
     } catch (error) {
      console.log(error)
      setLoading(false);
     }
    }
   
    fetchMonthly();
    fetchMothlyAnalytics();
    fetchStations();
  },[monthYear]);
  const modifiedData = stations.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentStations = modifiedData.slice(firstPostIndex, lastPostIndex);
  // const handleSearch = (e) => {
  //   let searchValue = e.target.value;
  //   if (e.target.value === "") {
  //     fetchStations();
  //   } else {
  //     filteredData = modifiedData.filter((value) => {
  //       return value.username.toLowerCase().includes(searchValue.toLowerCase());
  //     });
  //     setStations(filteredData);
  //   }
  // };
const labels = monthlyData.map((data)=>{
  return data.month +" "+ data.year;
})
var arrayD=[]
monthlyData.map((e)=>{
  e.data.map((d)=>{
    arrayD.push(d);
  })
})
var stationsTarget=0; 
stations.map((s)=>{
  
  stationsTarget += s.target;
})
const groupedD = arrayD.reduce((group, arr)=>{
    const {type} = arr;
    group[type] =group[type] ?? [];
    group[type].push(arr.amount);
    return group;
},{});

let fuels =Object.keys(groupedD)
const fuelDoghnut = modifiedSales.map((t)=>{
  return t.type
});
const fuelDoghnutAmount =modifiedSales.map((t)=>{
  return t.amount
});

 const data3 = {
  labels,
  datasets: fuels.map((f)=>{
    return {label: f, data: groupedD[f],backgroundColor: f=== "Diesel"?'#00CC00':f==="Petrol"?"#324DDD":"#FFC600" }
  }) 
 
};
const data = {
  
  datasets: [
    {
      data: [monthlyAnalytics.totalSales, Number(stationsTarget)-monthlyAnalytics.totalSales],
      backgroundColor: ["#00CC00", "#D9D9D9"],
      display: true,
      borderColor: "#000",
    },
  ],
  
};
const data2 ={
  labels: fuelDoghnut,
  datasets: [
    {
      data: fuelDoghnutAmount,
      backgroundColor: ["#59F159", "#FFC600", "#4D4DFF"], 
      borderWidth: 1,
      borderColor: "#fff",
      color: "#fff"
    }
  ]
}
  return (
    <section className="lg:w-[84%]  w-full lg:ml-48 mx-auto md:px-10 px-3 py-5 ">
    
      {loading?<div className="flex flex-col justify-center h-screen items-center">
            <LoadingPage />
            <p>Loading page...</p>
          </div>:<>
      <div className="flex flex-col w-full h-12 items-end mt-12">
        <select value={monthYear} onChange={(e)=>{setMonthYear(e.target.value)}} className="py-2 w-48 text-sm text-white px-3 bg-[#FFC600] rounded">
          <option value="">Filter by Month</option>
          {monthlyData.map((month)=>(
            <option value={month.monthNumber+"-"+month.year }>{month.month+"-"+month.year }</option>
          ))}
        </select>
      </div>
      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
        <div className="bg-[#220D98] rounded-xl pt-6 px-4">
          <div className="flex justify-between">
            <p className="text-white font-bold text-md">Sales</p>
            <div className="flex flex-col items-center">
            <p
                        className={
                          monthlyAnalytics.risenSales
                            ? "font-bold  rounded bg-[#00CC00] text-white"
                            : "font-bold  rounded bg-[#FF715B] text-white"
                        }
                      >
                        {monthlyAnalytics.risen ? "^" : "v"}{" "}
                        <span className="text-white text-md">
                          {Math.round(monthlyAnalytics.percentageSales*100)/100} %
                        </span>
                      </p>
              <p className="text-white text-xs">Since last month</p>
            </div>
          </div>
          <div className="relative">
            <p className="text-white relative">{Math.round(monthlyAnalytics.totalSales*100)/100}</p>
            <p className="absolute text-white text-[0.5rem] top-[-1px] left-20">
              Ltrs
            </p>
          </div>

          {modifiedSales.map((sales) => (
                    <div className=" flex gap-3 text-white items-center p-3">
                      <div
                        className={sales.type === "Diesel"?`bg-[#00CC00] w-2 h-2 rounded`:sales.type === "Petrol"?`bg-[#324DDD] w-2 h-2 rounded`:`bg-[#FFC600] w-2 h-2 rounded`}
                      ></div>
                      <p className="text-sm">{sales.type}</p>
                      <div className="relative">
                        <p className="text-white text-sm relative">
                          {Math.round(sales.amount*100)/100}
                        </p>
                        <p className="absolute text-white text-[0.5rem] top-[-1px] right-[-16px]">
                          Ltrs
                        </p>
                      </div>
                    </div>
                  ))}
        </div>

        <div className="bg-white h-[32.4vh] shadow-2xl relative rounded-xl pt-6 px-4 flex flex-col items-center justify-center">
          <p className="font-bold py-2">Sales Target</p>
          <p className="text-sm font-bold">
            <span className="font-normal text-slate-500">Target</span> {Number(stationsTarget)}L
          </p>
          <p className="text-sm">
            Reached <span className="font-bold">{Math.round(monthlyAnalytics.totalSales*100)/100}L</span>
          </p>
          <Doughnut
            data={data}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              rotation: -90,
              circumference: 180,
              cutout: "60%",
              maintainAspectRatio: true,
              responsive: true,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "90%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <div className="text-sm font-bold">{Math.round((monthlyAnalytics.totalSales/Number(stationsTarget))*100*100)/100} %</div>
          </div>
        </div>
        <div className="bg-[#220D98] w-full h-[32.4vh] text-white shadow-2xl relative rounded-xl  flex flex-col items-center justify-center">
        <Doughnut data={data2} options={options} height="200px"
  width="200px"/>
        </div>
      </div>

      <div className="md:h-[50vh] mt-14 h-full w-full mx-auto block">
      <Bar options={options2} data={data3} />
      </div>
 
      <section className="mx-auto block w-full mt-14">
        <div className="flex justify-between w-full items-center ">
          <h2 className="font-bold mx-8">Top Selling stations</h2>
         
        </div>
        {currentStations.length> 0?(
              <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
              <thead className="text-white">
            {currentStations.map((station)=>(
              <tr key={station.id} className="bg-[#1C144A] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2  sm:mb-0"
              >
              <th scope="col"
                          className="p-3 text-left">Station Name</th>
              <th scope="col"
                          className="p-3 text-left">Location</th>
              <th scope="col"
                          className="p-3 text-left">Region </th>
              <th scope="col"
                          className="p-3 text-left">Territory Manager</th>
              
              <th scope="col"
                          className="p-3 text-left">Gross sales</th>
               <th scope="col"
                          className="p-3 text-left">Target</th>
                           <th scope="col"
                          className="p-3 text-left">Rating</th>
            </tr>
            ))}
        </thead>
        <tbody className="flex-1 sm:flex-none">
          {currentStations.map((station)=>(
          <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0" key={station.stationId}>
          <td className="border-grey-light border hover:bg-gray-100 p-3">{station.stationName}</td>
          <td className="border-grey-light border hover:bg-gray-100 p-3">{station.location}</td>
          <td className="border-grey-light border hover:bg-gray-100 p-3">{station.region}</td>
          <td className="border-grey-light border hover:bg-gray-100 p-3">{station.territoryManager}</td>
          <td className="border-grey-light border hover:bg-gray-100 p-3">{Math.round(station.grossSales*100)/100}</td>
          <td className="border-grey-light border hover:bg-gray-100 p-3">{station.target}</td>
          <td className="border-grey-light border hover:bg-gray-100 p-3">{station.target > station.grossSales?<p className="text-red-500">Below Target</p>: <p className="text-green-500">Above Target</p>}</td>

        </tr>
          ))}

        </tbody>
      </table>):(
      <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
          <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
            {LoadingPage ? (
              <div className="block w-48 mx-auto">
                <LoadingPage /> <p>Loading Page...</p>
              </div>
            ) : (
              <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </div>
                <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                  No Data Found
                </h1>
              </div>
            )}
          
        </div>
      </div>
            )}
      <TablePagination
        setPostsPerPage={setPostsPerPage}
        totalPosts={modifiedData.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      </section>
      </>}

    </section>
  );
};

export default OwnerAnalytics;
