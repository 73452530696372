import React, { useEffect } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Privacy= () => {
    useEffect(()=>{
        goTop()
    })
    const goTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
  return (
    <div>
      <div className="py-16 w-full bg-[#1C144A]">
        <Link to="/" className="rounded px-3 py-2 bg-green-500 hover:bg-green-400 text-white flex items-center w-36 justify-center gap-6"><span><AiOutlineArrowLeft/></span> Back</Link>
        <h1 className="text-center text-white text-2xl">
        Fuelytic Privacy Policy
        </h1>
      </div>
      <div className="mt-4 bg-white rounded md:w-3/4 w-11/12 mx-auto p-3">
        <h2 className="font-bold text-xl py-2">Fuelytic Privacy Policy</h2>
        <h2 className="font-bold text-xl py-2">Effective Date: 2023-11-01</h2>
        <h2 className="font-bold">1. Introduction</h2>
        <p>
          Welcome to Fuelytic! Fuelytic ("we," "our," or "us") values your
          privacy and is committed to protecting your personal information. This
          Privacy Policy outlines our practices regarding the collection, use,
          and safeguarding of your data when you use our services.
        </p>
        <h2 className="font-bold">2. Information We Collect</h2>
        <ul className="list-disc pl-10">
          <li>
            <p>
              <span className="font-bold">Account Information : </span>When you
              create an account, we may collect personal information, including
              your name, email address, and other contact details.
            </p>
          </li>
          <li>
            <p>
              <span className="font-bold">User Content : </span>Content you
              provide when using our services, such as data inputs and
              communications.
            </p>
          </li>
          <li>
            <p>
              <span className="font-bold">Usage Data : </span>Information on how
              you interact with our services, including log data, device
              information, and usage patterns.
            </p>
          </li>
        </ul>
        <h2 className="font-bold">3. How We Use Your Information</h2>
        <p>We use your information for the following purposes:</p>
        <ul className="list-disc pl-10">
          <li>
            <p>To provide and maintain our services.</p>
          </li>
          <li>
            <p>To improve and personalize your experience.</p>
          </li>
          <li>
            <p>To send you service-related communications.</p>
          </li>
          <li>
            <p>To respond to your requests and provide customer support.</p>
          </li>
          <li>
            <p>To comply with legal obligations and protect our rights.</p>
          </li>
        </ul>
        <h2 className="font-bold">4. Data Sharing</h2>
        <p>
          We do not share your information with third-party apps, and we are
          committed to safeguarding your privacy.
        </p>
        <h2 className="font-bold">5. Data Security</h2>
        <p>
        We take appropriate measures to protect your information from unauthorized access and
disclosure. However, no online method is 100% secure, and we cannot guarantee absolute
security.
        </p>
        <h2 className="font-bold">6. Your Choices</h2>
        <p>
        You can update or delete your account and the data associated with it. You may also opt out
of receiving promotional communications from us.
        </p>
        <h2 className="font-bold">7. Children's Privacy</h2>
        <p>
        Our services are not intended for individuals under the age of 13. We do not knowingly
collect personal information from children under 13.
        </p>
        <h2 className="font-bold">8. Changes to this Policy</h2>
        <p>
        We may modify this Privacy Policy at any time. We will notify you of changes by posting the
updated policy on our website. By continuing to use our services after the changes, you
accept the revised Privacy Policy.
        </p>
        <h2 className="font-bold">9. Contact Us</h2>
        <p>
        If you have any questions, concerns, or requests regarding this Privacy Policy, please contact
us at:
Email: [Contact Email Address] Address: [Physical Address] Phone: [Contact Phone Number]
        </p>
        <h2 className="font-bold">Effective Date</h2>
        <p>
        This Privacy Policy is effective as of the date stated at the beginning of the document.
        </p>
      </div>
      <Footer/>
    </div>
  );
};

export default Privacy;
