import React from 'react'
import ReasonCard from '../../components/cards/ReasonCard'
import benefit1 from "../../assets/benefit1.png";
import benefit2 from "../../assets/benefit2.png";
import benefit3 from "../../assets/benefit3.png";
import benefit4 from "../../assets/benefit4.png";
import MetricCard from '../../components/cards/MetricCard';
const Whyus = () => {
  return (
    <section className='bg-white py-10 px-10 flex flex-col justify-center items-center' id="about">
        <h2 className='font-bold md:text-4xl text-2xl'>Why You Need</h2>
        <h2 className='font-bold md:text-4xl text-2xl text-center mt-3'>Our Retail Performance Tool?</h2>
        <div className='rounded-xl bg-[#42B56C] w-1/4 h-2'></div>

        <div className='grid md:grid-cols-2 grid-cols-1 mt-8 gap-10'>
            <ReasonCard img={benefit1} title="Target-Driven Performance Tracking" content="Set and monitor performance targets with ease. Our Platform provides target setting for individual stations, regions, and the entire network, providing marketers with tools to monitor achievements and drive performance."/>

            <ReasonCard img={benefit2} title="Informed Growth Strategies" content="Base your growth strategies on real insights, not assumptions. Our Platform empowers you with data-driven decision-making, allowing you to identify opportunities for sustainable and informed business expansion."/>

            <ReasonCard img={benefit3} title="Centeralized data Management" content="Simplifies your data management process, allowing you to consolidate all critical information in one platform. With centralized data, you can say goodbye to data vulnerabilities found in conventional channels like WhatsApp and Emails."/>

            <ReasonCard img={benefit4} title="Efficient Data Management" content="Simplify your data processes with Fuelytic streamlined data collection and management. Say goodbye to manual entries and data discrepancies, and embrace real-time insights for informed decision-making."/>
    </div>

    <div className="bg-[#1C144A] rounded-xl md:w-3/4 w-full mx-6 my-10 py-8 px-10 flex md:flex-row text-center flex-col items-center justify-center md:gap-4 gap-10 md:justify-between">
      <MetricCard percentage="80%" feature="Time Saving"/>
      <MetricCard percentage="50%" feature="Decrease in implemtation Cost "/>
      <MetricCard percentage="10X" feature="Improved Effeciency"/>
      <MetricCard percentage="24hr" feature="Customer Support"/>
    </div>
    </section>
  )
}

export default Whyus