
import kenya from "../../assets/kenya.png"
import uganda from "../../assets/uganda.png"
import zambia from "../../assets/zambia.png"
import {BsFillEnvelopeFill} from "react-icons/bs"
import {RiMapPin2Fill} from "react-icons/ri"
import React, { useState } from 'react'
import { HashLink } from 'react-router-hash-link';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "../../assets/logo_full.png"
import LoadingButton from "../../components/loading/LoadingButton"
import { Link } from "react-router-dom"
import { AiFillInstagram, AiFillLinkedin, AiFillYoutube } from "react-icons/ai"
const schema = z.object({
    firstName: string().min(3,{
        message: "Your name is required with 3 or more characters",
      }),
    companyName: string().min(4,{
      message: "Name is required with 4 or more characters",
    }),
    email: string().email({
        message: "Email is not valid",
      }),
    phone: string().min(9,{
      message: "Invalid phone number",
    }),
    message: string().min(4,{
        message: "Message is required with 4 or more characters",
      }),
    
  });
const Footer = () => {
    const { register, handleSubmit, formState } = useForm({
        defaultValues: { firstName:"",companyName:"",email:"",phone:"", message:""},
        resolver: zodResolver(schema),
      });
      const [loading, setLoading] = useState(false) 
      const { errors } = formState;
    
      const handleSend = async (formValues) => {
        setLoading(true)
        //call api
        try {
          await axios.post(
            `${process.env.REACT_APP_APIENDPOINT}operations/support/reply`,
            
            { title: formValues.title, message: formValues.message,  }
          );
          setLoading(false)
        } catch (error) {
          setLoading(false)
            console.log(error)
          toast.error("Error occurred");
        }
      };
  return (
    <section className=' py-10 px-12 bg-[#1C144A]' id="footer">
        <div className='flex flex-col justify-center items-center  w-full'>
            <h2 className='text-white font-bold text-3xl text-center'>Where We Operate  </h2>
            <h3 className='text-white font-bold text-xl text-center'>We are <span>Pan-Africa !</span>  </h3>
            <p className='text-white mt-4 text-center'>On our way to building a more digitized Africa, we’re Extending our Networks to  All over The African Continent.</p>
            <p className='text-white text-xs mt-6 font-bold'>Operating in 3 Countries</p>
           <div className='flex gap-4 md:flex-row flex-col justify-center items-center'>
             <div className='flex w-1/2 gap-4 mt-4 items-center'>
                <img src={uganda} className='h-8' alt="" />
                <p className='text-white text-xs'>Uganda</p>
            </div>
            <div className='flex w-1/2 gap-4 mt-4 items-center'>
                <img src={zambia} className='h-8' alt="" />
                <p className='text-white text-xs'>Zambia</p>
            </div>
            <div className='flex w-1/2 gap-4 mt-4 items-center'>
                <img src={kenya} className='h-8' alt="" />
                <p className='text-white text-xs'>Kenya</p>
            </div>
           </div>
        </div>

        <div className='flex w-full justify-between flex-wrap mt-6 '>
            <div className='md:w-3/12 w-full'>
                <p className='text-2xl font-bold text-white text-center'>Contact Information</p>
                <p className='mt-4  text-white text-center'>Say Something to start a live chat!</p>

                <div className='flex mt-10 justify-items-start gap-4 items-center'>
                    <BsFillEnvelopeFill className='text-white' size={24}/>
                    <p className='text-white text-xs'>Contact@fuelytic.org</p>
                </div>
                <div className='flex mt-10 justify-between gap-4 items-center w-full'>
                    <RiMapPin2Fill className='text-white' size={30}/>
                    <p className='text-white text-xs'>132 Dartmouth Street Boston, Massachusetts 02156 United States</p>
                </div>
            </div>
            <div className='md:w-7/12'>
            <form  onSubmit={handleSubmit(handleSend)} id="contact">
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  

                <div>
                  <label
                    className="text-white"
                    htmlFor="title"
                  >
                    FirstName
                  </label>
                  <input
                    id="firstName"
                    {...register("firstName")}
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-white border-b border-solid  border-white bg-transparent "
                  />
                  <p className="text-red-500 text-sm">{errors.firstName?.message}</p>
                </div>



                <div>
                  <label
                    className="text-white"
                    htmlFor="title"
                  >
                    Company Name
                  </label>
                  <input
                    id="title"
                    {...register("companyName")}
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-white border-b border-solid  border-white bg-transparent "
                  />
                  <p className="text-red-500 text-sm">{errors.companyName?.message}</p>
                </div>
                <div>
                  <label
                    className="text-white"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    {...register("email")}
                    type="email"
                    className="block w-full px-4 py-2 mt-2 text-white border-b border-solid  border-white bg-transparent "
                  />
                  <p className="text-red-500 text-sm">{errors.email?.message}</p>
                </div>
                <div>
                  <label
                    className="text-white"
                    htmlFor="phone"
                  >
                    Phone
                  </label>
                  <input
                    id="phone"
                    {...register("phone")}
                    type="number"
                    className="block w-full px-4 py-2 mt-2 text-white border-b border-solid  border-white bg-transparent "
                  />
                  <p className="text-red-500 text-sm">{errors.phone?.message}</p>
                </div>
                </div>
                <div className="mt-4">
                  <label
                    className="text-white"
                    htmlFor="message"
                  >
                    Message
                  </label>
                  <input
                    id="message"
                    {...register("message")}
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-white border-b border-solid  border-white bg-transparent "
                  />
                  <p className="text-red-500 text-sm">{errors.message?.message}</p>
                </div>
              

        
                <div className="flex w-full flex-wrap">
                    <p className="md:w-3/4 w-full"></p>
                <button className="p-3  mx-auto block md:w-1/4 w-full mt-4 leading-5 text-white transition-colors duration-300 transform bg-[#00CC00] rounded-xl hover:bg-green-600 ">
                {loading?<div className="flex gap-4"><LoadingButton/> <p>Please wait...</p></div>:"Send"}
                </button>   
                </div>
               
                
             
            </form>
            </div>
       
        </div>
        <div className="flex flex-col md:flex-row md:justify-between justify-center items-center md:items-start flex-wrap w-full mt-6">
            <div className="md:w-4/12 w-full ">
            <img src={logo} className="h-12" alt="" />
            <p className="text-sm text-center text-white  my-4"> Cloud retail performance Solution     For Oil Marketers in Africa.</p>
            </div>
            <ul className="grid grid-cols-2  gap-7">
                <li className="text-white"><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li className="text-white"><HashLink to="/#features">About</HashLink></li>
                {/* <li className="text-white"><Link to="/terms&conditions">T&C</Link></li> */}
                <li className="text-white"><HashLink to="/#whyus">Careers</HashLink></li>
                <li className="text-white"><HashLink to="/#footer">Contact</HashLink></li>
                <li className="text-white"><Link to="/login">Log in</Link></li>
            </ul>
            <div className="text-white">
                <p className="text-xl text-center">Socials</p>
                <div className="flex items-center gap-4 mt-6">
                <AiFillLinkedin size={30}/>
                <AiFillYoutube size={30}/>
                <AiFillInstagram size={30}/>
                </div>
            </div>
        </div>
        <ToastContainer/>
    </section>
  )
}

export default Footer