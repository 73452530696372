import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HomePage from "./HomePage";
import { FaArrowCircleUp } from 'react-icons/fa';
import Features from "./Features";
import mockup1 from "../../assets/mockup.png";
import "./landing.css";
import Whyus from "./Whyus";
import MoreInfo from "./MoreInfo";
import HowItWorks from "./HowItWorks";
import App from "./App";
import Footer from "./Footer";
import loginImage from "../../assets/login_image.png"
import LoadingPage from "../../components/loading/LoadingPage";
import SiteNav from "../../components/nav/SiteNav";
const Site = () => {
  const[isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false)
  const cachedImages =async(srcArray)=>{
    const promises =await srcArray.map((src)=>{
      return new Promise(function(resolve, reject){
        const img = new Image();


        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      })
    });

    await Promise.all(promises);
    setIsLoading(false);
  }

  useEffect(()=>{
    const imgs = [
      mockup1,
      loginImage
    ];
    cachedImages(imgs);
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  },[])
  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
if(isLoading){
  console.log("loading image...")
   return(<div className="flex flex-col justify-center h-screen items-center"><LoadingPage/><p>Loading page...</p></div>)
}else{
  console.log("image loaded")
  return (
    <>
      <Helmet>
      <meta name="title" content="Fuelytic - Retail Performance Solution for Oil Marketers In Africa"/>
<meta name="description" content="Fuelytic is a powerful cloud-based retail performance tool designed specifically for oil marketers in Africa."/>
        <meta
          name="keywords"
          content="fuel, fuelytic, fuelytics, oil marketters, retail performance tool, fuel market, Africa's Fuel, fuelytic Kenya, Fuelytics Kenya"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="1 days" />
        <meta name="author" content="Abshir Heris" />
      </Helmet>
      <SiteNav/>
      <HomePage />
      <Features />
      <Whyus />
      <MoreInfo />
      <HowItWorks/>
      <App/>
      <button onClick={goTop} className={isVisible?"fixed bg-[#00CC00] rounded-full p-4 right-10 text-center bottom-10 opacity-70 z-50":"hidden"}><FaArrowCircleUp/></button>
      <Footer/>
    </>
  );
}
};

export default Site;
