import axios from 'axios';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { addInfo } from '../../redux/userSlice';

const ChooseSide = () => {
    const {currentUser} = useSelector((state) => state.user)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const chooseSide =async (side)=>{
        console.log(side)
        if(side ==="Fuelytic Management"){
            navigate("/admin")
        }
        if(side ==="OMC"){
            navigate("/omc")
        }
        if(side ==="Territory Manager"){
            navigate("/region")
        }
        if(side  === "OMC Admin"){
            console.log("Hello")
            //call on to find franchiser
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_APIENDPOINT}operations/omc/${currentUser.user.id}`,
                    
                  );
                  console.log(res.data)
                  const fId =res.data.franchiserId;
                  const cu = {...currentUser, "franchiser":fId}
                  dispatch(addInfo(cu))
                  navigate("/omcadmins")
            } catch (error) {
                console.log(error)
            }
        }
    }
  return (
    <div className='flex flex-col justify-center items-center h-screen'>
        <h1 className='mb-4 font-bold text-3xl'>Choose side</h1>
        <div className='flex items-center justify-between'>
        {currentUser.user.roles.map((role)=>(
            <div onClick={()=>{chooseSide(role.roleName)}} className='p-10 border-slate-200 border-solid border cursor-pointer rounded shadow-md text-2xl'>{role.roleName}</div>
        ))}
        </div>
    </div>
  )
}

export default ChooseSide