import React, { useEffect, useState } from "react";
import { BiSolidEraser } from "react-icons/bi";
import welcome from "../../assets/welcome.png";
import CardUsers from "../../components/cards/CardUsers";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineArrowUp } from "react-icons/ai";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { useSelector } from "react-redux";
import axios from "axios";
import LoadingPage from "../../components/loading/LoadingPage";
import { Link } from "react-router-dom";
import moment from "moment";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Daily Sales Analytics",
    },
  },
};

const OwnerDashboardOMC = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [managers, setManagers] = useState([]);
  const [stations, setStations] = useState([]);
  const [users, setUsers] = useState([]);
  const [submission, setSubmission] = useState("");
  const [weekly, setWeekly] = useState([]);
  const [loading, setLoading] = useState(true);
  const [salesResponse, setSalesResponse] = useState({});
  const [modifiedSales, setModifiedSales] = useState([]);
  const dateString = moment(new Date()).format('YYYY-MM-DD').toString();
  const fetchTerritoryManager = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/managers/${currentUser.franchiser}`
      );
      setManagers(res.data);
    } catch (error) {}
  };
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const fetchStations = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/stations/${currentUser.franchiser}`
      );
      setStations(res.data);
      setLoading(false);
      console.log(res.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/users/${currentUser.franchiser}`
      );
      setUsers(res.data);
      setLoading(false);
      console.log(res.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchSales = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/daily/sales/${currentUser.franchiser}/${dateString}`
      );
      setSalesResponse(res.data);
      setModifiedSales(
        res.data.salesResponses.map(({ ...item }) => ({
          ...item,
          color:
            item.type === "Diesel"
              ? "#00CC00"
              : item.type === "Petrol"
              ? "#0F60FF"
              : "#E5E600",
        }))
      );
      setLoading(false);
      console.log(res.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  
  const fetchSalesGraphData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/weekly/${currentUser.franchiser}`
      );
      const arrayData = res.data;

      setWeekly(arrayData.reverse());

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchSubmissions = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/daily/submission/${currentUser.franchiser}/${dateString}`
      );
      setSubmission(res.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTerritoryManager();
    fetchStations();
    fetchUsers();
    fetchSales();
    fetchSalesGraphData();
    fetchSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const labels = weekly.map((data) => {
    return data.date;
  });
  const data = {
    labels,
    datasets: [
      {
        label: "Diesel",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "#00CC00",
      },
      {
        label: "Petrol",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "#324DDD",
      },
      {
        label: "Kerosene",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "#FFC600",
      },
    ],
  };

  var today = new Date();
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <section className="lg:w-[84%]  w-full lg:ml-[13rem] mx-auto md:px-10 py-5">
      <div>
        {loading ? (
          <div className="flex flex-col justify-center h-screen items-center">
            <LoadingPage />
            <p>Loading page...</p>
          </div>
        ) : (
          <div className="flex md:flex-row flex-col items-center justify-between gap-4 w-full mt-12">
            <div className="flex flex-col  md:w-7/12 w-full md:px-0 px-3">
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <p className="flex gap-2 items-center">
                    <span className="text-slate-400 md:text-md text-sm">
                      Welcome
                    </span>{" "}
                    <img src={welcome} className="h-5" alt="" />
                  </p>
                  <p className="text-blue-600 md:text-md text-sm">
                    {currentUser.user.username}
                  </p>
                </div>
                <div className="md:text-md text-sm flex flex-col items-end">
                  <p className="">{weekday[today.getDay()]}</p>
                  <p className="md:text-md text-sm">
                    {today.getDate()} {month[today.getMonth()].substring(0, 3)}{" "}
                    {today.getFullYear()}
                  </p>
                </div>
              </div>
              {/* cards analytics */}
              <div className="grid md:grid-cols-2 justify-items-center   mt-6 gap-8 grid-cols-1">
                <Link to="stationDetails" className="bg-[#220D98] rounded-xl pt-6 px-4 w-full md:w-3/4">
                  <div className="flex justify-between">
                    <p className="text-white font-bold text-md">Sales</p>
                    <div className="flex flex-col items-center">
                      <p
                        className={
                          salesResponse.risen
                            ? "font-bold  rounded bg-[#00CC00] text-white"
                            : "font-bold  rounded bg-[#FF715B] text-white"
                        }
                      >
                        {salesResponse.risen ? "^" : "v"}{" "}
                        <span className="text-white text-md">
                          {salesResponse.percentage==="NaN"?0:Math.round(salesResponse.percentage*100)/100} %
                        </span>
                      </p>
                      <p className="text-white text-xs">Since Yesterday</p>
                    </div>
                  </div>
                  <div className="relative">
                    <p className="text-white">{Math.round(salesResponse.fullAmount*100)/100}</p>
                    <p className="absolute text-white text-[0.5rem] top-[-1px] left-14">
                      Ltrs
                    </p>
                  </div>

                  {modifiedSales.map((sales) => (
                    <div className=" flex gap-3 text-white items-center">
                      <div
                        className={sales.type === "Diesel"?`bg-[#00CC00] w-2 h-2 rounded`:sales.type === "Petrol"?`bg-[#324DDD] w-2 h-2 rounded`:`bg-[#FFC600] w-2 h-2 rounded`}
                      ></div>
                      <p className="text-sm">{sales.type}</p>
                      <div className="relative">
                        <p className="text-white text-sm relative">
                          {sales.amount}
                        </p>
                        <p className="absolute text-white text-[0.5rem] top-[-1px] right-[-16px]">
                          Ltrs
                        </p>
                      </div>
                    </div>
                  ))}
                </Link>
                
                <Link to="stations/unsubmitted" className="p-4 rounded-xl shadow-2xl w-full md:w-3/4">
                  <p className="font-bold text-xl py-4">Submissions</p>
                  <div className="flex items-center gap-4">
                    <p className="text-[#2FD72F] text-2xl">
                      {(Number(submission.split("/")[0]) /
                        Number(submission.split("/")[1])) *
                        100}{" "}
                      %
                    </p>
                    <p>{submission}</p>
                  </div>
                  <p className="text-slate-500 py-2 text-xs">
                    Of Sites have Submitted Readings{" "}
                  </p>
                </Link>
              </div>
              {/* Graphs */}
              <div className="mt-6 md:h-[30%] h-80">
                <Bar options={options} data={data} />
              </div>
            </div>

            <div className="md:w-4/12 w-full px-10 md:px-1 flex flex-col">
              <div className="bg-[#1C144A] rounded-xl px-4 py-2">
                <div className="flex justify-between items-center ">
                  <p className="text-white font-bold">Territory Managers</p>
                  <BiSolidEraser className="text-white" size={20} />
                </div>
                <div className="grid grid-cols-4 gap-4 py-2">
                  {managers.map((manager) => (
                    <CardUsers name={manager.username} />
                  ))}
                </div>
              </div>
              <div className="bg-[#1C144A] rounded-xl flex flex-col justify-center px-4 mt-16 py-2">
                <div className="flex justify-between items-center ">
                  <p className="text-white font-bold text-center">My Company</p>
                  <BiSolidEraser className="text-white" size={20} />
                </div>
                <div className="p-4 border border-white border-solid w-3/4 mx-auto rounded mt-4 text-white ">
                  <div className="flex items-center justify-between ">
                    <p className="text-sm">Stations</p>
                    <BsThreeDotsVertical />
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <p className="font-bold text-2xl">{stations.length}</p>
                    <div className="flex items-center rounded-xl text-xs gap-2 bg-slate-200 text-blue-500 px-2">
                      <AiOutlineArrowUp />
                      <p>20%</p>
                    </div>
                  </div>
                </div>

                <div className="p-4 border border-white w-3/4 mx-auto border-solid rounded mt-4 text-white ">
                  <div className="flex items-center justify-between">
                    <p className="text-sm">Active users</p>
                    <BsThreeDotsVertical />
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <p className="font-bold text-2xl">{users.length}</p>
                    <div className="flex items-center rounded-xl text-xs gap-2 bg-slate-200 text-blue-500 px-2">
                      <AiOutlineArrowUp />
                      <p>20%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default OwnerDashboardOMC;
