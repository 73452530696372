import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { AiOutlineClose } from 'react-icons/ai';
import LoadingButton from '../loading/LoadingButton';
import { useSelector } from 'react-redux';
const schema = z.object({
    title: string().min(3,{
        message: "Title is required with 6 or more characters",
      }),
    message: string().min(4,{
      message: "Message is required with 4 or more characters",
    }),
    
  });
const Reply = ({setOpenReply, record}) => {
    const { register, handleSubmit, formState } = useForm({
        defaultValues: { title: "", message: ""},
        resolver: zodResolver(schema),
      });
      const {currentUser} = useSelector((state) => state.user)
      const [loading, setLoading] = useState(false) 
      const { errors } = formState;
      axios.defaults.headers.common = {
        'Authorization': 'Bearer ' + currentUser.token
    };
      const handleReply = async (formValues) => {
        setLoading(true)
        //call api
        try {
          await axios.post(
            `${process.env.REACT_APP_APIENDPOINT}operations/support/reply`,
            
            { title: formValues.title, message: formValues.message, id: record.id, email: record.userEmail }
          );
          setLoading(false)
        } catch (error) {
          setLoading(false)
            console.log(error)
          toast.error("Error occurred");
        }
      };
  return (
    <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
    <section className="max-w-4xl md:w-1/2 p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
          Reply Message
        </h2>
        <AiOutlineClose
          size={30}
          onClick={() => {
            setOpenReply(false);
          }}
        />
      </div>

            <form  onSubmit={handleSubmit(handleReply)}>
            <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  

                <div>
                  <label
                    class="text-gray-700 dark:text-gray-200"
                    htmlFor="title"
                  >
                    Title
                  </label>
                  <input
                    id="title"
                    {...register("title")}
                    type="text"
                    class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                  <p className="text-red-500 text-sm">{errors.title?.message}</p>
                </div>



                <div>
                  <label
                    class="text-gray-700 dark:text-gray-200"
                    htmlFor="message"
                  >
                    Message
                  </label>
                  <input
                    id="message"
                    {...register("message")}
                    type="text"
                    class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                  <p className="text-red-500 text-sm">{errors.message?.message}</p>
                </div>

                
              </div>

        

                <button class="p-3 mx-auto block w-full mt-4 leading-5 text-white transition-colors duration-300 transform bg-[#00CC00] rounded-xl hover:bg-green-600 ">
                {loading?<div className="flex gap-4"><LoadingButton/> <p>Please wait...</p></div>:"Send"}
                </button>
                
             
            </form>
          
          <ToastContainer/>
    </section>

    </div>
  )
}

export default Reply