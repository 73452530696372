import React from 'react'

const FeatureCard = ({img,title, content }) => {
  return (
    <div className='flex flex-col items-start justify-between'>
        <img src={img} alt=''/>
        <h2 className='font-bold text-xl '>{title}   </h2>
        <br />
        <p className='text-[#596780]'>{content}</p>
        <br />
    </div>
  )
}

export default FeatureCard