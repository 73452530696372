/* eslint-disable react-hooks/exhaustive-deps */

import { AiOutlineClose, AiFillMinusCircle,AiFillPlusCircle } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingButton from "../../components/loading/LoadingButton";
import { useSelector } from "react-redux";

const AddStation = ({setOpen}) => {
    
    
    
    const [tanks, setTanks] = useState([{tankName:"", capacity:"", productType:"1", pumps: [{pumpName:""}]}]);
    const {currentUser} = useSelector((state) => state.user)
    const [loading, setLoading] = useState(false);
    const [dealers, setDealers] = useState([]);
    const [input, setInput] = useState({name:"", dealerId:0, target:""});
    axios.defaults.headers.common = {
      'Authorization': 'Bearer ' + currentUser.token
  };
   
    const handleAddTank = ()=>{
        setTanks([...tanks,{tankName:"", capacity:"", productType:"", pumps:[{pumpName:""}]}])
    }
    const deleteTank =(i)=>{
      if(tanks.length > 1){
        setTanks(tanks.splice(i,1))
      }
    }
    const handleAddPump =(index)=>{
      let newTank = [...tanks]
      newTank.forEach((tank,i)=>{
        if(index===i){
          console.log("found")
          tank.pumps.push({pumpName:""})
        }
      });
      console.log(newTank);
      setTanks(newTank)
    }
    const handleRemovePump =(index)=>{
      let newTank = [...tanks]
      newTank.forEach((tank,i)=>{
        if(index===i){
          console.log("found")
          tank.pumps.pop()
        }
      });
      console.log(newTank);
      setTanks(newTank)
    }

    useEffect(() => {
      const fetchDealer = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_APIENDPOINT}operations/dealers/${currentUser.user.id}`,
          
          );
         
          setDealers(res.data);
         
        } catch (error) {
        
          console.log(error.message);
        }
      };
      fetchDealer();
    }, []);
     const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    
  };
  const handleTankChangeInput =(e,i)=>{
    const {name, value} = e.target;
    const onChangeTankVal =[...tanks];
    onChangeTankVal[i][name] = value;
    setTanks(onChangeTankVal);
  }
  const handlePumpChangeInput =(e,i,p)=>{
    const {name, value} = e.target;
    const onChangePumpVal =[...tanks];
    onChangePumpVal[i]["pumps"][p][name] = value;
    setTanks(onChangePumpVal);
  }
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const addStation = async()=>{
    setLoading(true);
    console.log(input)
    try {
      const res1 =await axios.post(
        `${process.env.REACT_APP_APIENDPOINT}users/addStations`,
        
        {
          name:input.stationName,
          dealerId: input.dealerId,
          target:input.target
        }
        );
        tanks.forEach(async(tank, index)=>{
         const res2 = await axios.post(
            `${process.env.REACT_APP_APIENDPOINT}operations/tanks/add`,
            {
              tankName:tank.tankName,
              capacity:tank.capacity,
              fuelId:tank.productType,
              stationId:res1.data.id
            }
          );

          
            tank.pumps.forEach(async(p)=>{
              await axios.post(
                `${process.env.REACT_APP_APIENDPOINT}operations/pumps/add`,
                {
                  pump:p.pumpName,
                  tankId:res2.data.id
                }
              );
            })
            window.location.reload();
         
        })
        setLoading(false);  
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }
  return (
    <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
      <section className="max-w-4xl md:w-1/2 p-6 mx-auto bg-white max-h-[85vh] overflow-y-auto rounded-md shadow-md dark:bg-gray-800">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Add Station
          </h2>
          <AiOutlineClose
            size={30}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>

        <form>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4 ">
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="username"
              >
                Name
              </label>
              <input
                type="text"
                onChange={handleChange}
                name="stationName"
                placeholder="Station Name"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
              
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="region"
              >
                Dealer
              </label>
              <select
                type="text"
                name="dealerId"
                onChange={handleChange}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="">Select Dealer</option>
                {dealers.map((d)=>(
                  <option key={d.id} value={d.id}>{d.name}</option>
                ))}
              </select>
              
            </div>
            
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="username"
              >
                Target
              </label>
              <input
                type="text"
                name="target"
                onChange={handleChange}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
            </div>
            
            </div>
            <br />
            <hr />
            
            
            <p className="p-2 text-center text-xl"> Add Station Tanks</p>
            <hr />
           
            {
              tanks.map((tank,index)=>(
                <>
                <p>Tank ({index+1})</p>
                <div className="grid gap-6 md:grid-cols-2 grid-cols-1">
                <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="tankName"
              >
                Tank Name
              </label>
              <input
                type="text"
                name="tankName"
                value={tank.tankName}
                onChange={(e)=>{handleTankChangeInput(e,index)}}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="capacity"
              >
                Capacity
              </label>
              <input
                type="number"
                name="capacity"
                value={tank.capacity}
                onChange={(e)=>{handleTankChangeInput(e,index)}}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
            </div>
            <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="productType"
              >
                Product Type
              </label>
              <select
                value={tank.productType}
                name="productType"
                onChange={(e)=>{handleTankChangeInput(e,index)}}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="1">Petrol</option>
                <option value="2">Diesel</option>
                <option value="3">Kerosene</option>
              </select>
            </div>
            </div>
            <br />

            <p></p>
           
            <div className="grid gap-6 md:grid-cols-2 grid-cols-1">
            {tank.pumps.map((pump,i)=>(
              
              <div>
              <label
                className="text-gray-700 dark:text-gray-200"
                htmlFor="pumpName"
              >
                Pump Name ({i+1})
              </label>
              <input
                type="text"
                name="pumpName"
                value={pump.pumpName}
                onChange={(e)=>handlePumpChangeInput(e,index,i)}
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
            </div>
           
            ))}
             </div>
            <div className="flex justify-between flex-wrap">
            {tanks[index].pumps.length>1&& <div className="flex gap-2 justify-start items-center cursor-pointer" onClick={()=>{handleRemovePump(index)}} >
                <p className="text-[#06152B]">Remove Pump</p>
                <AiFillMinusCircle size={20} className="text-[#1C144A] cursor-pointer" />
            </div>}
             <div className="flex gap-2 justify-end items-center cursor-pointer" onClick={()=>{handleAddPump(index)}} >
                <p className="text-[#06152B]">Add Pump</p>
                <AiFillPlusCircle size={20} className="text-[#1C144A] cursor-pointer" />
            </div>
            </div>
             <br />
             <hr />
             <br /> 
             
                </>
              ))
            }
            <div className="flex flex-wrap justify-between">
            {tanks.length>1 && <div className="flex gap-2 justify-end items-center cursor-pointer" onClick={deleteTank}>
                <p className="text-[#06152B]">Remove Tank</p>
                <AiFillMinusCircle size={20} className="text-[#1C144A] cursor-pointer" onClick={deleteTank}/>
            </div>}
            <div className="flex gap-2 justify-end items-center cursor-pointer" onClick={handleAddTank}>
                <p className="text-[#06152B]">Add Tank</p>
                <AiFillPlusCircle size={20} className="text-[#1C144A] cursor-pointer" onClick={handleAddTank}/>
            </div>
            </div>
          <div className="flex justify-end mt-6">
            <button
              type="button"
              onClick={addStation}
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            >
               {loading?<div className="flex gap-4"><LoadingButton/> <p>Adding...</p></div>:"Add"}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};
 

export default AddStation