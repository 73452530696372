import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <section className='container px-4 mx-auto'>
      <div className='flex flex-col h-[80vh] items-center justify-center gap-4'>
        <p className='text-3xl font-bold text-center'>404 Page Not Found</p>
        <p className='text-xl text-center'>The page you are looking for was not found</p>
        <Link to="/" class="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
    Return to safety
</Link>
    </div>
    </section>
  )
}

export default PageNotFound