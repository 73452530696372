import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from "../../components/loading/LoadingButton";
import { updateUserInfo } from "../../redux/userSlice";

const schema = z.object({
  firstName: string().min(3, {
    message: "A First Name is required and should be more than 3 characters",
  }),
  lastName: string().min(3,{
    message: "A Last Name is required and should be more than 3 characters",
  }),

  email: string().email(),
  password : string().min(3,{
    message: "A Password is required and should be more than 7 characters",
  }),
  innitialPassword : string().min(3,{
    message: "A Password is required and should be more than 7 characters",
  }),
  passwordConfirm: string()
  
}).refine((data) => data.password === data.passwordConfirm, {
  message: "Passwords don't match",
  path: ["passwordConfirm"],
});
const Settings = () => {
  const {currentUser} = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const { register, handleSubmit, formState } = useForm({
    defaultValues:  { firstName: `${currentUser.user.username.split(" ")[0]}`, lastName: `${currentUser.user.username.split(" ")[1] === undefined? "":currentUser.user.username.split(" ")[1] }`,  email:`${currentUser.user.email}`, innitialPassword:"", password:"", passwordConfirm:""},
    resolver: zodResolver(schema),
  });
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;
 

  const handleUpdate = async (formValues) => {
    setLoading(true)
    try {
      await axios.post(`${process.env.REACT_APP_APIENDPOINT}users/login`,{username: currentUser.user.email ,password:formValues.innitialPassword});
      axios.defaults.headers.common = {
        'Authorization': 'Bearer ' + currentUser.token
    };
    const res = await axios.put(
      `${process.env.REACT_APP_APIENDPOINT}users/update/${currentUser.user.username}`,
      
      { username: `${formValues.firstName } ${formValues.lastName}`, email: formValues.email, password: formValues.password}
    );
    dispatch(updateUserInfo(res.data))
    setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      toast.error("Wrong current password")
    }
   
  
   
  };
  return (
    <section className="lg:w-[85%]  w-full lg:ml-48 mx-auto md:px-10 px-4 py-5">
         <div className='flex flex-col justify-center items-center md:h-[80vh] h-[110vh]'>
          <h1 className='py-2 mt-6 md:mt-0 font-bold text-2xl text-center'>Settings</h1>
            <form onSubmit={handleSubmit(handleUpdate)}>
            <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                <div>
                  <label
                    class="text-gray-700 dark:text-gray-200"
                    htmlFor="firstName"
                  >
                    FirstName
                  </label>
                  <input
                    id="firstName"
                    {...register("firstName")}
                    type="text"
                    placeholder="John"
                    class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                   <p className="text-red-500 text-sm">{errors.firstName?.message}</p>
                </div>

                <div>
                  <label
                    class="text-gray-700 dark:text-gray-200"
                    htmlFor="lastName"
                  >
                    LastName
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    {...register("lastName")}
                    placeholder="Smit"
                    class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                  <p className="text-red-500 text-sm">{errors.lastName?.message}</p>
                </div>



                <div>
                  <label
                    class="text-gray-700 dark:text-gray-200"
                    htmlFor="workEmail"
                  >
                    Email
                  </label>
                  <input
                    id="workEmail"
                    {...register("email")}
                    type="email"
                    class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  />
                  <p className="text-red-500 text-sm">{errors.email?.message}</p>
                </div>

                <div>
                <label class="text-gray-700 dark:text-gray-200" htmlFor="innitialPassword">Innitial Password</label>
                <input {...register("innitialPassword")} id="innitialPassword" type="password" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.innitialPassword?.message}</p>
            </div>
                <div>
                <label class="text-gray-700 dark:text-gray-200" htmlFor="password">Password</label>
                <input {...register("password")} id="password" type="password" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.password?.message}</p>
            </div>

            <div>
                <label class="text-gray-700 dark:text-gray-200" htmlFor="passwordConfirmation">Password Confirmation</label>
                <input {...register("passwordConfirm")}  id="passwordConfirmation" type="password" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.passwordConfirm?.message}</p>
            </div>
              </div>

        

                <button type="submit" class="p-3 mx-auto block w-full mt-4 leading-5 text-white transition-colors duration-300 transform bg-[#00CC00] rounded-xl hover:bg-green-600 ">
                {loading?<div className="flex gap-4"><LoadingButton/> <p>Please wait...</p></div>:"Update"}
                </button>
                
             
            </form>
          </div>
          <ToastContainer/>
    </section>
  )
}

export default Settings