import React, {useState} from "react";
import logo from "../../assets/logo_black.png";
import {
  AiFillHome,
  AiOutlineSetting,
  AiOutlinePieChart,
  AiOutlineStock,
} from "react-icons/ai";
import {FiLogOut} from "react-icons/fi"
import {
  MdGasMeter,
  MdOutlineLocalGasStation,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FaTools } from "react-icons/fa";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BsBoxFill } from "react-icons/bs";
import Topnav from "../nav/Topnav";
import { useDispatch, useSelector } from "react-redux";
import Login from "../../pages/auth/Login";
import { logout } from "../../redux/userSlice";
import AccessDenied from "../../pages/errors/AccessDenied";
import axios from "axios";
const OwnerSideNav = () => {
  const[open, setOpen] = useState(false);
  const {currentUser} = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const pathname = useLocation().pathname.split("/")[2];
  const logoutUser = ()=>{
    delete axios.defaults.headers.common["Authorization"];
      //remove the currentUser
      dispatch(logout());
      return <Login/>
  }
  if(!currentUser || !currentUser.user){
    return <Login/>
  }else if(currentUser.user.roles[0].id !== 6){
    return <AccessDenied/>
  }
  else{
  return (
    <div  >
      <div className={open?"fixed bg-white top-0 left-0 bottom-0 z-30":"fixed hidden lg:block top-0 left-0 bottom-0"}>
      <aside className="flex w-56 py-4 pl-10 flex-col h-screen justify-between items-start">
        <img src={logo} className="h-9" alt="" />

        <div className="flex flex-col gap-1">
          <Link to="" onClick={()=>setOpen(false)} className="flex gap-2 items-center my-2">
            <AiFillHome />
            <p classname="text-sm">Dashboard</p>
          </Link>
          <Link to="analytics" onClick={()=>setOpen(false)} className="flex gap-2 items-center my-2">
            <AiOutlinePieChart />
            <p classname="text-sm">Analytics</p>
          </Link>
          <Link to="performance" onClick={()=>setOpen(false)} className="flex gap-2 items-center my-2">
            <AiOutlineStock />
            <p classname="text-sm">Performace center</p>
          </Link>
          <Link to="logistics" onClick={()=>setOpen(false)} className="flex gap-2 items-center my-2">
            <MdGasMeter />
            <p classname="text-sm">Logistics</p>
          </Link>
          <Link to="stations" onClick={()=>setOpen(false)} className="flex gap-2 items-center my-2">
            <MdOutlineLocalGasStation />
            <p classname="text-sm">Stations</p>
          </Link>
          <Link to="users" onClick={()=>setOpen(false)} className="flex gap-2 items-center my-2">
            <HiOutlineUserGroup />
            <p classname="text-sm">Users</p>
          </Link>
          
        </div>
        <div className="mr-12 flex flex-col gap-1">
          <Link to="" onClick={()=>setOpen(false)} className="flex relative gap-2 items-center my-2">
            <FaTools />
            <p classname="text-sm ">Maintenance</p>
            <span className="absolute top-[-14px] right-[-40px] rounded-xl px-2 text-sm py text-white bg-[#00CC00]">Beta</span>
          </Link>
          <Link to="" onClick={()=>setOpen(false)} className="flex relative gap-2 items-center my-2">
            <BsBoxFill />
            <p classname="text-sm">Assets</p>
            <span className="absolute top-[-8px] right-[0px] rounded-xl px-2 text-sm py text-white bg-[#00CC00]">Beta</span>
          </Link>
        </div>

        <div className="flex items-start flex-col gap-1">
          
          <Link to="settings" onClick={()=>setOpen(false)} className="flex gap-2 items-center my-2">
            <AiOutlineSetting />
            <p classname="text-sm">Settings</p>
          </Link>
          <Link to="support" onClick={()=>setOpen(false)} className="flex gap-2 items-center my-2">
            <MdOutlineSupportAgent />
            <p classname="text-sm">Support</p>
          </Link>
          <Link to="" onClick={logoutUser} className="flex gap-2 items-center my-2">
            <FiLogOut  />
            <p classname="text-sm">Logout</p>
          </Link>
        </div>
      </aside>
      </div>
      <Topnav title={pathname==="" || pathname === undefined? "Dashboard": pathname === "analytics"?"Analytics":pathname ==="performance"?"Performance Center":pathname === "logistics"?"Logistics":pathname==="stations"?"Stations":pathname === "users"?"Employees":""} setOpen={setOpen} open={open}/>
      <Outlet open={open} setOpen={setOpen}/>
    </div>
  );
};
}
export default OwnerSideNav;
