import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import LoadingButton from '../../components/loading/LoadingButton';
import { useSelector } from 'react-redux';
const schema = z.object({
 
  message: string().min(4,{
    message: "Message is required",
  }),
  
});
const OwnerSupportOMC = () => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { message: ""},
    resolver: zodResolver(schema),
  });
  const {currentUser} = useSelector((state) => state.user)
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const handleSend= async (formValues) => {
    setLoading(true)
    //call api
    try {
      await axios.post(
        `${process.env.REACT_APP_APIENDPOINT}operations/support/send`,
        
        { userEmail: currentUser.user.email, message: formValues.message, isRead:0, isReplied:0,createdOn:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), modifiedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") }
      );
      toast.success("Message sent. You will get a response soon.")
      setLoading(false)
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred")
        console.log(error)
      toast.error("Credentials are invalid");
    }
  };
  return (
    <section className="lg:w-[85%]  w-full lg:ml-48 mx-auto px-6 py-5">
        <div className='flex flex-col justify-center items-center h-[80vh]'>
          <h1 className='py-6 font-bold text-md text-center'>Send us your feedback or any query regarding our system</h1>
            <form onSubmit={handleSubmit(handleSend)}  className='md:w-1/2 w-full'>
            <div>
              <label
                htmlFor="message"
                className="block text-sm text-gray-500 "
              >
                Message
              </label>

              <textarea
              {...register("message")}
              cols={12}
              rows={10}
                className="block  mt-2 w-full placeholder-gray-400/70  rounded-lg border border-gray-400 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-700 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40    "
              ></textarea>
               <p className="text-red-500 text-sm">{errors.message?.message}</p>
            </div>
            <button type="submit" className='mx-auto block md:w-1/2 w-full px-3 py-2 bg-[#00CC00] hover:bg-green-500 text-white text-center rounded mt-4'> {loading?<div className="flex gap-4"><LoadingButton/> <p>Please wait...</p></div>:"Send"}</button>
            </form>
        </div>
        <ToastContainer/>
    </section>
  )
}

export default OwnerSupportOMC