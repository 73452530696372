import React from 'react'
const CardUsers = ({ name}) => {
  const colors = ["bg-[#176BEF]","bg-[#ff3e30]", "bg-[#f7b529]", "bg-[#179c52]" ]
  const randomColor = colors[Math.floor(Math.random()*colors.length)];
  return (
    <div className='flex flex-col items-center justify-center'>
       <div className={`w-14 h-14 relative flex flex-col justify-center items-center rounded-full ${randomColor}`}>
       <p className='text-white text-center'>{name[0]}</p>
       </div>
        <p className='text-white text-center'>{name}</p>
    </div>
  )
}

export default CardUsers