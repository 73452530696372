import React, { useState } from "react";
import logo from "../../assets/logo_full.png";
import { Outlet, Link } from "react-router-dom";
import { Link as HashLink,} from "react-scroll";
const SiteNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  console.log(process.env.REACT_APP_APIENDPOINT);
  return (
    <div className="bg-[#1C144A]">
      <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="relative flex items-center justify-between">
          <HashLink
            to="/"
            aria-label="Company"
            title="Company"
            className="inline-flex items-center"
          >
            <img src={logo} className="h-10" alt="" />
          </HashLink>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            <li>
              <HashLink
                activeClass="active"
                to="features"
                spy={true}
                smooth={true}
                offset={50}
                duration={1000}
                className="font-medium tracking-wide cursor-pointer text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                Features
              </HashLink>
            </li>
            <li>  
              <HashLink
                activeClass="active"
                to="howitworks"
                spy={true}
                smooth={true}
                offset={50}
                duration={1500}
                className="font-medium tracking-wide cursor-pointer text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                How it works
              </HashLink>
            </li>
            <li>
              <HashLink
                activeClass="active"
                to="whyus"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="font-medium tracking-wide cursor-pointer text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                Careers
              </HashLink>
            </li>
            <li>
              <HashLink
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={50}
                duration={1500}
                className="font-medium tracking-wide cursor-pointer text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                About
              </HashLink>
            </li>

            <li>
              <HashLink
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={50}
                duration={2000}
                className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                ContactUs
              </HashLink>
            </li>
          </ul>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            <li>
              <Link
                to="/login"
                aria-label="About us"
                title="About us"
                className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                Signin
              </Link>
            </li>
            <li>
              <HashLink
              activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={50}
                duration={2000}
                className="inline-flex items-center cursor-pointer rounded-3xl justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#00CC00] hover:bg-green-400 focus:shadow-outline focus:outline-none"
                aria-label="Sign up"
                title="Sign up"
              >
                Book demo
              </HashLink>
            </li>
          </ul>
          <div className="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg className="w-5 text-white" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full">
                <div className="p-5 bg-[#1C144A] border rounded shadow-sm">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <HashLink
                        to="/"
                        aria-label="Company"
                        title="Company"
                        className="inline-flex items-center"
                      >
                        <img src={logo} className="h-8" alt="" />
                      </HashLink>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-600 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-white" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4">
                      <li>
                        <HashLink
                          activeClass="active"
                          to="features"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={2000}
                          className="font-medium cursor-pointer tracking-wide text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Features
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          activeClass="active"
                          to="howitworks"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={2000}
                          className="font-medium cursor-pointer tracking-wide text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          How it Works
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          activeClass="active"
                          to="whyus"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={2000}
                          className="font-medium cursor-pointer cursor-pointer tracking-wide text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Careers
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          activeClass="active"
                          to="about"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={2000}
                          className="font-medium tracking-wide cursor-pointer text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          About
                        </HashLink>
                      </li>
                      <li>
                        <HashLink
                          activeClass="active"
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={2000}
                          className="font-medium tracking-wide cursor-pointer text-white transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          ContactUs
                        </HashLink>
                      </li>
                      <li>
                        <Link
                          to="/login"
                          className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                          aria-label="Sign up"
                          title="Sign up"
                        >
                          Sign in
                        </Link>
                      </li>
                      <li>
                        <HashLink
                          activeClass="active"
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={2000}
                          className="inline-flex items-center cursor-pointer justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#00CC00] hover:bg-green-500 focus:shadow-outline focus:outline-none"
                          aria-label="Sign up"
                          title="Sign up"
                        >
                          Book demo
                        </HashLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default SiteNav;
