import React from 'react'

const MetricCard = ({percentage, feature}) => {
  return (
    <div className='flex flex-col items-center justify-center'>
        <p className='text-white text-3xl font-extrabold'>{percentage}</p>
        <p className='text-[#00CC00] text-sm'>{feature}</p>
    </div>
  )
}

export default MetricCard