import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineArrowUp, AiOutlinePlus } from "react-icons/ai";
import AddStation from "../../components/modals/AddStation";
import TablePagination from "../../components/pagination/Pagination";
import axios from "axios";
import "../../App.css"
import { useSelector } from "react-redux";
import LoadingPage from "../../components/loading/LoadingPage";
const StationsOMC = () => {
    const [open, setOpen] = useState(false);
    const [stations, setStations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(7);
    //const [record, setRecord] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const {currentUser} = useSelector((state) => state.user)
  let [filteredData] = useState();
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const fetchStations = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/stations/${currentUser.franchiser}`,
      
      );
      setStations(res.data);
      setLoadingPage(false);
      console.log(res.data)
    } catch (error) {
      setLoadingPage(false);
      console.log(error);
    }
  };

  useEffect(() => {
   
    fetchStations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const modifiedData = stations.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  console.log(stations);
  const currentStations = modifiedData.slice(firstPostIndex, lastPostIndex);
  const handleSearch = (e) => {
    let searchValue = e.target.value;
    if (e.target.value === "") {
      fetchStations();
    } else {
      filteredData = modifiedData.filter((value) => {
        return value.stationName.toLowerCase().includes(searchValue.toLowerCase());
      });
      setStations(filteredData);
    }
  };
  return (
    <section className="lg:w-[85%]  w-full lg:ml-48 mx-auto md:px-10 px-3 py-5">
      {loadingPage?<div className="flex flex-col justify-center h-screen items-center">
            <LoadingPage />
            <p>Loading page...</p>
          </div>:<>
      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-16">
        <div className="border border-slate-200 border-solid shadow rounded p-4">
          <div className="flex items-center justify-between ">
            <p className="text-sm">Stations</p>
            <BsThreeDotsVertical />
          </div>
          <div className="flex items-center justify-between pt-2">
            <p className="text-4xl">{stations.length}</p>
            <div className="flex items-center rounded-xl text-xs gap-2 bg-slate-200 text-blue-500 px-2">
              <AiOutlineArrowUp />
              <p>20%</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex md:items-center md:justify-between flex-col justify-center gap-4 md:flex-row">
        

        <div className="relative flex items-center mt-4 md:mt-0">
            <span className="absolute">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
            </span>

            <input type="text" onChange={handleSearch} placeholder="Search" className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
        </div>
        <button onClick={()=>{setOpen(true);}} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#1C144A] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-[#1C144A]focus:ring-opacity-80">
        <AiOutlinePlus/>

    <span className="mx-1">Add Station</span>
</button>
    </div>

      <section className="mx-auto block w-full mt-8">
        
        {currentStations.length> 0?(
        <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
        <thead className="text-white">
           {currentStations.map((station)=>(
             <tr key={station.id} className="bg-[#1C144A] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
             >
             <th className="p-3 text-left">Station Name</th>
             <th className="p-3 text-left">Location</th>
             <th className="p-3 text-left">Region</th>
             <th className="p-3 text-left">Territory Manager</th>
             <th className="p-3 text-left">Target</th>
           </tr>
           ))}
          </thead>
          <tbody className="flex-1 sm:flex-none">
            {currentStations.map((station)=>(
              <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                <td className="border-grey-light border hover:bg-gray-100 p-3">{station.stationName}</td>
                <td className="border-grey-light border hover:bg-gray-100 p-3">{station.location}</td>
                <td className="border-grey-light border hover:bg-gray-100 p-3">{station.region}</td>
                <td className="border-grey-light border hover:bg-gray-100 p-3">{station.territoryManager}</td>
                <td className="border-grey-light border hover:bg-gray-100 p-3">{station.target}</td>
            </tr>
            ))}
           
          </tbody>
        </table>):(
      <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
          <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
            {loadingPage ? (
              <div className="block w-48 mx-auto">
                <LoadingPage /> <p>Loading Page...</p>
              </div>
            ) : (
              <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </div>
                <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                  No Data Found
                </h1>
              </div>
            )}
          
        </div>
      </div>
            )}
            <TablePagination
        setPostsPerPage={setPostsPerPage}
        totalPosts={modifiedData.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      </section>
      {open && <AddStation setOpen= {setOpen}/>}
      </>}
    </section>
  );
};

export default StationsOMC;
