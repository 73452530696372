import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import LoadingPage from "../../components/loading/LoadingPage";
import { Link } from "react-router-dom";
import moment from "moment";

const Performance = () => {
  const [performance, setPerformance] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  let [filteredData] = useState();
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  var today = new Date();
  const fetchPerformance = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIENDPOINT}operations/performance/${currentUser.user.id}/${moment(startDate).format('YYYY-MM-DD').toString()}`
      );
      console.log(res.data);
      setPerformance(
        res.data.map(({ ...i }) => ({
          ...i,
          stationAnalyticsResponses: i.stationAnalyticsResponses.map(
            ({ ...item }) => ({
              ...item,
              color:
                item.type === "Diesel"
                  ? "#00CC00"
                  : item.type === "Petrol"
                  ? "#0F60FF"
                  : "#E5E600",
            })
          ),
        }))
      );
     
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    
    fetchPerformance();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser,startDate]);
  const handleSearch = (e)=>{
  let searchValue = e.target.value;
    if (e.target.value === "") {
      fetchPerformance();
    } else {
      filteredData = performance.filter((value) => {
        return value.stationName.toLowerCase().includes(searchValue.toLowerCase());
      });
      setPerformance(filteredData);
    }
  }
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <section className="lg:w-[85%]  w-full lg:ml-48 mx-auto   py-5">
      {loading ? (
        <div className="flex flex-col justify-center h-screen items-center">
          <LoadingPage />
          <p>Loading performance...</p>
        </div>
      ) : (
        <div>
          {/* search and filters */}
          <div className="flex justify-between items-center md:mx-[9.8rem] mt-12">
            <div className=" w-1/2 relative">
              <span className="absolute left-0 top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>

              <input
                type="text"
                onChange={handleSearch}
                placeholder="Search Stations..."
                className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            <div className="md:text-md text-sm flex flex-col items-end">
                  <p className="">{weekday[today.getDay()]}</p>
                  <p className="md:text-md text-sm">
                    {today.getDate()} {month[today.getMonth()].substring(0, 3)}{" "}
                    {today.getFullYear()}
                  </p>
                  <DatePicker className="px-2 py-3 border rounded-lg border-slate-300 border-solid" maxDate={new Date()}  selected={startDate} onChange={(date) => {setStartDate(date); }} />
                </div>
          </div>
          {performance.length > 0 ? (
            <div className="flex flex-col mt-14">
              {performance.map((details) => (
                <Link to="/omc/stations" className="bg-[#1C144A] rounded-[4rem] w-11/12 lg:w-3/4 mx-auto mt-2 pb-4 flex px-4 flex-col ">
                  <p className="font-bold text-2xl text-white text-center pb-2">
                    {details.stationName}
                  </p>
                  <div className="grid lg:grid-cols-4 gap-4 sm:grid-cols-2 ">
                    <div className="bg-white  rounded-xl pb-2 px-2 w-full mb-2 ">
                      <div className="flex justify-between w-full">
                        <p className="text-black font-bold text-md">
                          Sales
                        </p>
                      </div>
                      <div className="flex items-center w-full">
                        <div className="relative">
                          <p className="text-black">{Math.round(details.totalSales*100)/100}</p>
                          <p className="absolute text-black text-[0.5rem] top-[-1px] left-10">
                            Ltrs
                          </p>
                        </div>
                        <div className="flex flex-col items-end w-full justify-between">
                          <p
                            className={
                              details.risenSales
                                ? "font-bold  rounded bg-[#00CC00] text-black"
                                : "font-bold  rounded bg-[#FF715B] text-black"
                            }
                          >
                            {details.risenSales ? "^" : "v"}{" "}
                            <span className="text-black text-xs">
                              {details.percentageSales === "NaN"?0: Math.round(details.percentageSales * 100) / 100}{" "}
                              %
                            </span>
                          </p>
                          <p className="text-black text-[0.5rem]">
                            Since Yesterday
                          </p>
                        </div>
                      </div>

                      {details.stationAnalyticsResponses.map((p) => (
                        <div className=" flex gap-3 text-black items-center">
                          <div
                            className={p.type === "Diesel"?`bg-[#00CC00] w-2 h-2 rounded`:p.type === "Petrol"?`bg-[#324DDD] w-2 h-2 rounded`:`bg-[#FFC600] w-2 h-2 rounded`}
                          ></div>
                          <p className="text-sm">{p.type}</p>
                          <div className="relative">
                            <p className="text-black text-sm relative">
                              {Math.round(p.sales*100)/100}
                            </p>
                            <p className="absolute text-black text-[0.5rem] top-[-1px] right-[-16px]">
                              Ltrs
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="bg-white shadow-2xl rounded-xl pb-2 px-4 ">
                      <div className="flex justify-between">
                        <p className="text-black py-2 font-bold text-md text-center">
                          Shortage
                        </p>
                      </div>
                      {details.stationAnalyticsResponses.map((s) => (
                        <div className=" flex gap-3 text-black items-center">
                          <div
                            className={s.type === "Diesel"?`bg-[#00CC00] w-2 h-2 rounded`:s.type === "Petrol"?`bg-[#324DDD] w-2 h-2 rounded`:`bg-[#FFC600] w-2 h-2 rounded`}
                          ></div>
                          <p className="text-sm">{s.type}</p>
                          <div className="relative">
                            <p className="text-black text-sm relative">
                              {Math.round(s.shortage * 100) / 100}
                            </p>
                            <p className="absolute text-black text-[0.5rem] top-[-1px] right-[-16px]">
                              Ltrs
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="rounded-2xl pb-2  bg-white ">
                      <p className="text-center font-bold py-2">Water check</p>
                      {details.stationAnalyticsResponses.map((s) => (
                        <div className=" flex gap-3 pl-4 text-black items-center">
                          <div
                            className={s.type === "Diesel"?`bg-[#00CC00] w-2 h-2 rounded`:s.type === "Petrol"?`bg-[#324DDD] w-2 h-2 rounded`:`bg-[#FFC600] w-2 h-2 rounded`}
                          ></div>
                          <p className="text-sm">{s.type}</p>
                          <div className="relative">
                            <p className="text-black text-xs relative">
                              {s.waterChecks}
                            </p>
                            <p className="absolute text-black text-[0.5rem] top-[-1px] right-[-16px]">
                              Ltrs
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="rounded-2xl py-2  bg-white">
                      <p className="text-center font-bold">RTT</p>
                      {details.stationAnalyticsResponses.map((s) => (
                        <div className=" flex gap-3 pl-4 text-black items-center">
                          <div
                            className={s.type === "Diesel"?`bg-[#00CC00] w-2 h-2 rounded`:s.type === "Petrol"?`bg-[#324DDD] w-2 h-2 rounded`:`bg-[#FFC600] w-2 h-2 rounded`}
                          ></div>
                          <p className="text-sm">{s.type}</p>
                          <div className="relative">
                            <p className="text-black text-sm relative">
                              {s.rtt}
                            </p>
                            <p className="absolute text-black text-[0.5rem] top-[-1px] right-[-16px]">
                              Ltrs
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="mx-auto mt-[20vh] text-center text-xl">
              No Station Found
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Performance;
