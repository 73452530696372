import React from 'react'
import engineers  from "../../assets/engineers.png"
import appstore from "../../assets/appstore.png";
import playstore from "../../assets/playstore.png";
import appgallery from "../../assets/appgallery.png";
import { Link } from 'react-router-dom';
const App = () => {
  return (
    <section className='flex bg-white md:flex-row gap-10 flex-col items-center justify-center pb-10'>
        <div className='md:w-5/12 w-full'>
            <img src={engineers} alt="" className='w-10/12 mx-auto' />
        </div>
        <div className='flex flex-col items-center justify-center'>
        <h2 className='font-bold md:text-4xl text-2xl text-center mt-3'>  Download Our Free App </h2>
        <div className='rounded-xl bg-[#42B56C] w-1/4 h-2'></div> 
        <h2 className='font-bold md:text-4xl text-2xl text-center mt-3'>Today</h2>
        <div className="flex mt-6 justify-center gap-4 flex-wrap">
        <Link>
          <img className="h-10" src={appstore} alt="" />
        </Link>
        <Link>
          <img className="h-10" src={playstore} alt="" />
        </Link>
          <img className="h-12" src={appgallery} alt="" />
        
      </div>
        </div>
    </section>
  )
}

export default App