import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LoadingPage from "../../components/loading/LoadingPage";
import DatePicker from "react-datepicker";
import TablePagination from "../../components/pagination/Pagination";

const Unsubmitted = () => {
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(7);
  const { currentUser } = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState(new Date());
  var today = new Date();
  let [filteredData] = useState();
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.token
};
  const fetchUnsubmitted = async () => {
    try {
      setLoading(true);
      const date = moment(
        startDate
      )
        .format("YYYY-MM-DD")
        .toString();
      const res = await axios.get(
        `${
          process.env.REACT_APP_APIENDPOINT
        }operations/daily/submission/stations/${currentUser.user.id}/${date}`
      );
      
      setStations(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUnsubmitted();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser,startDate]);
  const modifiedData = stations.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentStations = modifiedData.slice(firstPostIndex, lastPostIndex);
  const handleSearch = (e) => {
    let searchValue = e.target.value;
    if (e.target.value === "") {
      fetchUnsubmitted();
    } else {
      filteredData = performance.filter((value) => {
        return value.stationName
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setStations(filteredData);
    }
  };
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <section className="lg:w-[85%]  w-full lg:ml-48 mx-auto   py-5">
      {loading ? (
        <div className="flex flex-col justify-center h-screen items-center">
          <LoadingPage />
          <p>Loading Stations...</p>
        </div>
      ) : (
        <div>
          {/* search and filters */}
          <div className="flex md:justify-between items-center md:mx-[4rem] md:px-0 px-4 mt-12 justify-center md:flex-row flex-col">
            <div className=" md:w-1/2 relative w-full">
              <span className="absolute left-0 top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>

              <input
                type="text"
                onChange={handleSearch}
                placeholder="Search Stations..."
                className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            <div className="md:text-md text-sm w-full flex flex-col items-end">
              <p className="">{weekday[today.getDay()]}</p>
              <p className="md:text-md text-sm">
                {today.getDate()} {month[today.getMonth()].substring(0, 3)}{" "}
                {today.getFullYear()}
              </p>
              <DatePicker
                className="px-2 py-3 border rounded-lg border-slate-300 border-solid"
                maxDate={new Date()}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </div>
          </div>
          <section className="mx-auto block md:w-11/12 mt-8">
          <h2 className="text-center text-xl font-bold">Unsubmitted Stations for {moment(
        startDate
      )
        .format("YYYY-MM-DD")
        .toString()}</h2>
        {currentStations.length> 0?(
        <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
        <thead className="text-white">
           {currentStations.map((station)=>(
             <tr key={station.id} className="bg-[#1C144A] flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
             >
             <th className="p-3 text-left">Station Name</th>
             <th className="p-3 text-left">Site Manager</th>
             <th className="p-3 text-left">Phone Number</th>
           </tr>
           ))}
          </thead>
          <tbody className="flex-1 sm:flex-none">
            {currentStations.map((station)=>(
              <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                <td className="border-grey-light border hover:bg-gray-100 p-3">{station.stationName}</td>
                <td className="border-grey-light border hover:bg-gray-100 p-3">{station.siteManager}</td>
                <td className="border-grey-light border hover:bg-gray-100 p-3">{station.phoneNumber}</td>
            </tr>
            ))}
           
          </tbody>
        </table>):(
      <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
          <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
            {loading ? (
              <div className="block w-48 mx-auto">
                <LoadingPage /> <p>Loading Station...</p>
              </div>
            ) : (
              <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </div>
                <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                  No Data Found
                </h1>
              </div>
            )}
          
        </div>
      </div>
            )}
            <TablePagination
        setPostsPerPage={setPostsPerPage}
        totalPosts={modifiedData.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      </section>
        </div>
        
      )}
    </section>
  );
};

export default Unsubmitted;
