import React from 'react'
import { AiOutlineUser, AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useSelector } from 'react-redux';

const Topnav = ({title, open, setOpen}) => {
  const {currentUser} = useSelector((state) => state.user)
  
  return (
    <nav className={open?"fixed right-0  left-56 top-0 h-16 z-40 bg-white":"fixed right-0 lg:left-56 left-0 top-0 h-16 z-40 bg-white"}>
    <div className="flex w-full items-center h-full md:px-6  justify-between">
      <div className="flex gap-2 items-center font-bold md:text-2xl text-xl">{title} <AiOutlineMenu className={open?`hidden lg:hidden `:`block lg:hidden`} onClick={()=>{setOpen(true)}}/> <AiOutlineClose className={open?`block lg:hidden absolute top-2 left-[-1rem] z-50`:`hidden lg:hidden`} onClick={()=>{setOpen(false)}}/></div>
      <div className="flex gap-3 items-center">
        <div className="flex items-center">
          <AiOutlineUser size={30} />
          <div className="flex flex-col items-center">
            <p className="md:text-sm text-xs font-bold">{currentUser.user.username}</p>
            <p className="md:text-sm text-xs text-slate-400">{currentUser.user.roles[0].roleName}</p>
          </div>
        </div>
      </div>
    </div>
    </nav>
  )
}

export default Topnav